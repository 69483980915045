import React, { useState, useEffect } from 'react';
import FormBuilder from './FormBuilder';
import BiodataPreview from './BiodataPreview';
import { getTranslation, dateFormats, numberFormats } from './translations';
import { useBiodataStorage } from './hooks/useBiodataStorage';

// Let's add translations for the title and tagline
const translations = {
  english: {
    formBuilderTitle: 'Create Your Biodata',
    formBuilderTagline: 'Fill in your details to create a professional biodata'
  },
  hindi: {
    formBuilderTitle: 'अपना बायोडाटा बनाएं',
    formBuilderTagline: 'एक पेशेवर बायोडाटा बनाने के लिए अपना विवरण भरें'
  },
  marathi: {
    formBuilderTitle: 'तुमचा बायोडाटा तयार करा',
    formBuilderTagline: 'व्यावसायिक बायोडाटा तयार करण्यासाठी तुमचा तपशील भरा'
  },
  gujarati: {
    formBuilderTitle: 'તમારો બાયોડેટા બનાવો',
    formBuilderTagline: 'વ્યાવસાયિક બાયોડેટા બનાવવા માટે તમારી વિગતો ભરો'
  },
  kannada: {
    formBuilderTitle: 'ನಿಮ್ಮ ಬಯೋಡಾಟಾ ರಚಿಸಿ',
    formBuilderTagline: 'ವೃತ್ತಿಪರ ಬಯೋಡಾಟಾ ರಚಿಸಲು ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಭರ್ತಿ ಮಾಡಿ'
  }
};


// Custom hook for translations
const useTranslation = (language) => {
  return {
    t: (key) => getTranslation(language, key),
    dateFormat: dateFormats[language],
    numberFormat: numberFormats[language]
  };
};

// Language Selector Component
const LanguageSelector = ({ languages, selectedLanguage, onLanguageSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = React.useRef(null);

  const { t } = useTranslation(selectedLanguage);

  const getCurrentLanguageLabel = () => {
    const currentLang = languages.find(lang => lang.id === selectedLanguage);
    return currentLang ? currentLang.label : 'English';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative flex items-center gap-2" ref={dropdownRef}>
      <div className="flex items-center justify-center w-9 h-9 bg-rose-500 rounded-full">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-5 w-5 text-white"
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
          />
        </svg>
      </div>

      <button
        type="button"
        className="inline-flex items-center justify-between w-28 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{getCurrentLanguageLabel()}</span>
        <svg
          className={`ml-2 h-5 w-5 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 top-full">
          <div className="py-1" role="menu" aria-orientation="vertical">
            {languages.map((language) => (
              <button
                key={language.id}
                className={`w-full text-left px-4 py-2 text-sm ${
                  selectedLanguage === language.id
                    ? 'bg-rose-50 text-rose-700'
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
                role="menuitem"
                onClick={() => {
                  onLanguageSelect(language.id);
                  setIsOpen(false);
                }}
              >
                {language.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const initialSections = [

 
  {
    id: 'personalDetails',
    title: 'Personal Details',
    fields: [
      { id: 'name', label: 'Name', type: 'text', required: true },
      { id: 'dateOfBirth', label: 'Date of Birth', type: 'date', required: true },
      { id: 'timeOfBirth', label: 'Time of Birth', type: 'time', required: false },
      { id: 'placeOfBirth', label: 'Place of Birth', type: 'text', required: true },
      {
        id: 'height',
        label: 'Height',
        type: 'select',
        required: true,
        options: Array.from({ length: 59 }, (_, i) => {
          const feet = Math.floor(i / 12) + 4;
          const inches = i % 12;
          const cm = Math.round(((feet * 12 + inches) * 2.54));
          return {
            value: `${feet}_${inches}`,
            label: `${feet} feet ${inches} inches (${cm} cm)`
          };
        })
      },
      {
        id: 'maritalStatus',
        label: 'Marital Status',
        type: 'select',
        required: true,
        options: [
          { value: 'single', label: 'Single' },
          { value: 'divorced', label: 'Divorced' },
          { value: 'widowed', label: 'Widowed' },
          { value: 'separated', label: 'Separated' }
        ]
      },
      {
        id: 'complexion',
        label: 'Complexion',
        type: 'select',
        required: false,
        options: [
          { value: 'very_fair', label: 'Very Fair' },
          { value: 'fair', label: 'Fair' },
          { value: 'wheatish', label: 'Wheatish' },
          { value: 'brown', label: 'Brown' },
          { value: 'dark', label: 'Dark' }
        ]
      },
      {
        id: 'bloodGroup',
        label: 'Blood Group',
        type: 'select',
        required: false,
        options: [
          { value: 'a_positive', label: 'A+' },
          { value: 'a_negative', label: 'A-' },
          { value: 'b_positive', label: 'B+' },
          { value: 'b_negative', label: 'B-' },
          { value: 'o_positive', label: 'O+' },
          { value: 'o_negative', label: 'O-' },
          { value: 'ab_positive', label: 'AB+' },
          { value: 'ab_negative', label: 'AB-' }
        ]
      },
      { id: 'education', label: 'Education', type: 'text', required: false },
      { id: 'collegeName', label: 'College Name', type: 'text', required: false },
      { id: 'occupation', label: 'Job/Occupation', type: 'text', required: false },
      { id: 'organizationName', label: 'Organization Name', type: 'text', required: false },
      {
        id: 'annualIncome',
        label: 'Annual Income',
        type: 'select',
        required: false,
        options: [
          { value: '0-5', label: '0-5 LPA' },
          { value: '5-10', label: '5-10 LPA' },
          { value: '10-15', label: '10-15 LPA' },
          { value: '15-20', label: '15-20 LPA' },
          { value: '20-25', label: '20-25 LPA' },
          { value: '25-30', label: '25-30 LPA' },
          { value: '30-35', label: '30-35 LPA' },
          { value: '35-40', label: '35-40 LPA' },
          { value: '40-45', label: '40-45 LPA' },
          { value: '45-50', label: '45-50 LPA' },
          { value: '50+', label: '50+ LPA' }
        ]
      },
      { id: 'religion', label: 'Religion', type: 'text', required: false },
      { id: 'motherTongue', label: 'Mother Tongue', type: 'text', required: false },
      { id: 'caste', label: 'Caste', type: 'text', required: false },
{ id: 'subCaste', label: 'Sub Caste', type: 'text', required: false },
{
  id: 'rashi',
  label: 'Rashi',
  type: 'select',
  required: false,
  options: [
    { value: 'mesh', label: 'Mesh (Aries)' },
    { value: 'vrishabha', label: 'Varishabha (Taurus)' },
    { value: 'mithuna', label: 'Mithuna (Gemini)' },
    { value: 'karka', label: 'Karka (Cancer)' },
    { value: 'simha', label: 'Simha (Leo)' },
    { value: 'kanya', label: 'Kanya (Virgo)' },
    { value: 'tula', label: 'Tula (Libra)' },
    { value: 'vrischika', label: 'Vrischika (Scorpio)' },
    { value: 'dhanur', label: 'Dhanur (Sagittarious)' },
    { value: 'makara', label: 'Makara (Capricorn)' },
    { value: 'kumbha', label: 'Kumbha (Aquarius)' },
    { value: 'meena', label: 'Meena (Pisces)' }
  ]
},
{
  id: 'nakshatra',
  label: 'Nakshatra',
  type: 'select',
  required: false,
  options: [
    { value: 'ashwini', label: 'Ashwini' },
    { value: 'bharani', label: 'Bharani' },
    { value: 'krittika', label: 'Krittika' },
    { value: 'rohini', label: 'Rohini' },
    { value: 'mrigashira', label: 'Mrigashira' },
    { value: 'ardra', label: 'Ardra' },
    { value: 'punarvasu', label: 'Punarvasu' },
    { value: 'pushya', label: 'Pushya' },
    { value: 'ashlesha', label: 'Ashlesha' },
    { value: 'magha', label: 'Magha' },
    { value: 'purva_phalguni', label: 'Purva Phalguni' },
    { value: 'uttara_phalguni', label: 'Uttara Phalguni' },
    { value: 'hasta', label: 'Hasta' },
    { value: 'chitra', label: 'Chitra' },
    { value: 'swati', label: 'Swati' },
    { value: 'vishakha', label: 'Vishakha' },
    { value: 'anuradha', label: 'Anuradha' },
    { value: 'jyeshtha', label: 'Jyeshtha' },
    { value: 'mula', label: 'Mula' },
    { value: 'purva_ashadha', label: 'Purva Ashadha' },
    { value: 'uttara_ashadha', label: 'Uttara Ashadha' },
    { value: 'shravana', label: 'Shravana' },
    { value: 'dhanishta', label: 'Dhanishta' },
    { value: 'shatabhisha', label: 'Shatabhisha' },
    { value: 'purva_bhadrapada', label: 'Purva Bhadrapada' },
    { value: 'uttara_bhadrapada', label: 'Uttara Bhadrapada' },
    { value: 'revati', label: 'Revati' }
  ]
},
{ id: 'gotra', label: 'Gotra', type: 'text', required: false },
      {
        id: 'manglik',
        label: 'Manglik',
        type: 'select',
        required: false,
        options: [
          { value: 'no', label: 'No' },
          { value: 'yes', label: 'Yes' },
          { value: 'partial', label: 'Partial (Anshik)' }
        ]
      }
    ]
  },
  {
    id: 'familyDetails',
    title: 'Family Details',
    fields: [
      { id: 'fatherName', label: "Father's Name", type: 'text', required: true },
      { id: 'fatherOccupation', label: "Father's Occupation", type: 'text', required: false },
      { id: 'fatherOrganization', label: "Father's Organization", type: 'text', required: false },
      { id: 'motherName', label: "Mother's Name", type: 'text', required: false },
      { id: 'motherOccupation', label: "Mother's Occupation", type: 'text', required: false },
      { id: 'motherOrganization', label: "Mother's Organization", type: 'text', required: false },
      { id: 'totalBrothers', label: 'Total Brothers', type: 'number', required: false },
      { id: 'brothersMarried', label: 'Brothers Married', type: 'number', required: false },
      { id: 'totalSisters', label: 'Total Sisters', type: 'number', required: false },
      { id: 'sistersMarried', label: 'Sisters Married', type: 'number', required: false },
      {
        id: 'familyType',
        label: 'Family Type',
        type: 'select',
        required: false,
        options: [
          { value: 'nuclear', label: 'Nuclear Family' },
          { value: 'joint', label: 'Joint Family' },
          { value: 'extended', label: 'Extended Family' }
        ]
      },
      {
        id: 'familyValues',
        label: 'Family Values',
        type: 'select',
        required: false,
        options: [
          { value: 'traditional', label: 'Traditional' },
          { value: 'moderate', label: 'Moderate' },
          { value: 'liberal', label: 'Liberal' }
        ]
      },
      {
        id: 'familyStatus',
        label: 'Family Status',
        type: 'select',
        required: false,
        options: [
          { value: 'middle_class', label: 'Middle Class' },
          { value: 'upper_middle_class', label: 'Upper Middle Class' },
          { value: 'rich', label: 'Rich' },
          { value: 'affluent', label: 'Affluent' }
        ]
      },
      { id: 'nativePlace', label: 'Native Place', type: 'text', required: false }
    ]
  },
  {
    id: 'contactDetails',
    title: 'Contact Details',
    fields: [
      { id: 'address', label: 'Residential Address', type: 'textarea', required: false },
      { id: 'phone', label: 'Phone Number', type: 'tel', required: true },
      { id: 'alternatePhone', label: 'Alternate Phone', type: 'tel', required: false },
      { id: 'email', label: 'Email Address', type: 'email', required: false },
    ]
  },
  {
    id: 'photoUpload',
    title: 'Add Your Photo',
    fields: [
      {
        id: 'profilePhoto',
        label: 'Profile Photo',
        type: 'image',
        required: false,
        description: 'Upload an image (max 20MB)'
      }
    ]
  },

  {
    id: 'customization',
    title: 'Customization',
    fields: [
      {
        id: 'mantra',
        label: 'Mantra',
        type: 'text',
        required: false,
        defaultValue: '॥ Shree Ganeshay Namah ॥'
      },
      {
        id: 'biodataTitle',
        label: 'Biodata Title',
        type: 'text',
        required: false,
        defaultValue: 'Biodata'
      },
      {
        id: 'godImage',
        label: 'God Image',
        type: 'image',
        required: false,
        defaultValue: 'ganesha.png'
      },
    ]
  },
];

const BioDataMaker = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [formData, setFormData] = useState({});
  const [sections, setSections] = useState(initialSections);
  const [customLabels, setCustomLabels] = useState({});
  const { storedBiodata } = useBiodataStorage();

    // Initialize customLabels from localStorage
    useEffect(() => {
      const savedLabels = localStorage.getItem('customLabels');
      if (savedLabels) {
        try {
          setCustomLabels(JSON.parse(savedLabels));
        } catch (error) {
          console.error('Error loading custom labels:', error);
        }
      }
    }, []);

  const languages = [
    { id: 'english', label: 'English' },
    { id: 'hindi', label: 'हिंदी' },
    { id: 'marathi', label: 'मराठी' },
    { id: 'gujarati', label: 'ગુજરાતી' },
    { id: 'kannada', label: 'ಕನ್ನಡ' }
  ];

  // Initialize language from localStorage
  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    if (savedLanguage && languages.some(lang => lang.id === savedLanguage)) {
      setSelectedLanguage(savedLanguage);
    } else {
      setSelectedLanguage('english');
    }
  }, [languages]);

  // Initialize data from localStorage
  useEffect(() => {
    if (storedBiodata) {
      setFormData(storedBiodata.formData || {});
      setSelectedLanguage(storedBiodata.selectedLanguage || 'english');
      setSections(storedBiodata.sections || initialSections);
    }
  }, [storedBiodata, initialSections]);

  // Handle language selection
  const handleLanguageSelect = (languageId) => {
    setSelectedLanguage(languageId);
    localStorage.setItem('preferredLanguage', languageId);
  };

  const handleFormChange = (newFormData, newSections, newCustomLabels) => {
    setFormData(newFormData);
    if (newSections) {
      setSections(newSections);
    }
    if (newCustomLabels) {
      setCustomLabels(newCustomLabels);
    }

    // Store updated data in localStorage
    const biodataStorage = {
      formData: newFormData,
      selectedLanguage,
      sections: newSections || sections,
      customLabels: newCustomLabels || customLabels,
      lastUpdated: new Date().toISOString()
    };
    localStorage.setItem('biodataStorage', JSON.stringify(biodataStorage));
  };

  return (
    <div className="min-h-screen bg-rose-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Updated header section with reduced vertical spacing */}
        <div className="flex flex-col gap-1 mb-6">
          <div className="flex justify-between items-center w-full">
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-700">
              {translations[selectedLanguage].formBuilderTitle}
            </h1>
            <div className="relative z-20">
              <LanguageSelector 
                languages={languages}
                selectedLanguage={selectedLanguage}
                onLanguageSelect={handleLanguageSelect}
              />
            </div>
          </div>
          <p className="text-gray-500">
            {translations[selectedLanguage].formBuilderTagline}
          </p>
        </div>
  
        {/* Main content grid */}
        <div className="grid md:grid-cols-12 gap-6">
          {/* Form section */}
          <div className="md:col-span-7">
            <div className="bg-white rounded-lg shadow p-6">
              <FormBuilder 
                formData={formData}
                onFormChange={handleFormChange}
                selectedLanguage={selectedLanguage}
                sections={sections}
                initialData={storedBiodata?.formData}
                customLabels={customLabels}
              />
            </div>
          </div>
  
          {/* Preview section */}
          <div className="md:col-span-5">
            <div className="sticky top-6">
              <BiodataPreview 
                formData={formData}
                selectedLanguage={selectedLanguage}
                sections={sections}
                customLabels={customLabels}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BioDataMaker;