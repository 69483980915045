// App.jsx
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Hero from './components/Hero';
import HowItWorks from './components/HowItWorks';
import Templates from './components/Templates';
import BioDataMaker from './components/BioDataMaker/BioDataMaker';
import BiodataAppFeatures from './components/biodataAppFeatures';
import FAQs from './components/FAQs';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';

const App = () => {
  const location = useLocation();

  // Function to get page title based on route
  const getPageTitle = (pathname) => {
    switch (pathname) {
      case '/':
        return 'Matrimonial Biodata | Create Marriage Biodata Online';
      case '/templates':
        return 'Marriage Biodata Templates | Matrimonial Biodata';
      case '/how-it-works':
        return 'How It Works | Matrimonial Biodata';
      case '/create-biodata':
        return 'Create Marriage Biodata | Matrimonial Biodata';
      case '/faqs':
        return 'Frequently Asked Questions | Matrimonial Biodata';
      case '/terms-conditions':
        return 'Terms and Conditions | Matrimonial Biodata';
      case '/privacy-policy':
        return 'Privacy Policy | Matrimonial Biodata';
      case '/refund-policy':
        return 'Refund Policy | Matrimonial Biodata';
      case '/contact-us':
        return 'Contact Us | Matrimonial Biodata';
      case '/about-us':
        return 'About Us | Matrimonial Biodata';
      default:
        return 'Matrimonial Biodata | Create Marriage Biodata Online';
    }
  };

  // Meta description based on route
  const getMetaDescription = (pathname) => {
    switch (pathname) {
      case '/':
        return 'Create professional marriage biodata online with Matrimonial Biodata Maker. Choose from beautiful templates and download your biodata in PDF format instantly.';
      case '/templates':
        return 'Browse our collection of beautiful marriage biodata templates. Choose the perfect template for your matrimonial biodata and customize it easily.';
      case '/how-it-works':
        return 'Learn how to create your marriage biodata in simple steps. Fill details, choose template, preview and download your biodata instantly.';
      case '/create-biodata':
        return 'Create your marriage biodata now. Fill your details, add photo and download your professional biodata in PDF format.';
      case '/faqs':
        return 'Find answers to frequently asked questions about creating marriage biodata, templates, downloading and more.';
      case '/contact-us':
        return 'Contact us for any queries about marriage biodata maker. We are here to help you create the perfect biodata.';
      case '/about-us':
        return 'Learn about Matrimonial Biodata - The easiest way to create professional marriage biodata online.';
      default:
        return 'Create professional marriage biodata online with Matrimonial Biodata Maker. Choose from beautiful templates and download your biodata in PDF format instantly.';
    }
  };

  useEffect(() => {
    // Update document title
    document.title = getPageTitle(location.pathname);

    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', getMetaDescription(location.pathname));
    }

    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="flex flex-col min-h-screen bg-rose-50/30">
      <Header />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Templates />
              <BiodataAppFeatures />
              <HowItWorks />
            </>
          } />
          <Route path="/templates" element={<Templates />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/create-biodata" element={<BioDataMaker />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;