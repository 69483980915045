// FormBuilder.jsx
import React, { useState, useEffect, useMemo } from 'react';
import { FaPlus, FaTrash, FaArrowUp, FaArrowDown, FaFileAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import ImageUploader from './ImageUploader';
import { getTranslation } from './translations';
import { useBiodataStorage } from './hooks/useBiodataStorage';
import EditableLabel from './EditableLabel';
import TemplateSelector from './TemplateSelector';

// CustomSelect Component
const CustomSelect = ({ field, value, onChange, required, selectedLanguage }) => {
  const t = (key) => getTranslation(selectedLanguage, key);

  // Handle height field
  if (field.id === 'height') {
    return (
      <select
        className="w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:ring-0 focus:border-rose-500 bg-white"
        value={value || ""}
        onChange={onChange}
        required={required}
      >
        <option value="">{t(`sections.${field.sectionId}.fields.${field.id}.placeholder`)}</option>
        {field.options.map((option) => (
          <option key={option.value} value={option.value}>
            {selectedLanguage === 'hindi' 
              ? `${option.label.replace('feet', 'फीट').replace('inches', 'इंच').replace('cm', 'सेमी')}`
              : option.label
            }
          </option>
        ))}
      </select>
    );
  }

  // Handle blood group field
  if (field.id === 'bloodGroup') {
    const bloodGroupLabels = {
      a_positive: 'A+', a_negative: 'A-',
      b_positive: 'B+', b_negative: 'B-',
      o_positive: 'O+', o_negative: 'O-',
      ab_positive: 'AB+', ab_negative: 'AB-'
    };

    return (
      <select
        className="w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:ring-0 focus:border-rose-500 bg-white"
        value={value || ""}
        onChange={onChange}
        required={required}
      >
        <option value="">{t(`sections.${field.sectionId}.fields.${field.id}.placeholder`)}</option>
        {field.options.map((option) => (
          <option key={option.value} value={option.value}>
            {bloodGroupLabels[option.value]}
          </option>
        ))}
      </select>
    );
  }

  // Handle Rashi and Nakshatra fields
  if (field.id === 'rashi' || field.id === 'nakshatra') {
    return (
      <select
        className="w-full px-3 py-2 border text-gray-700 border-gray-300 rounded-md focus:ring-0 focus:border-rose-500 bg-white"
        value={value || ""}
        onChange={onChange}
        required={required}
      >
        <option value="">{t(`sections.${field.sectionId}.fields.${field.id}.placeholder`)}</option>
        {field.options.map((option) => (
          <option key={option.value} value={option.value}>
            {t(`sections.${field.sectionId}.fields.${field.id}.options.${option.value}`)}
          </option>
        ))}
      </select>
    );
  }

  // Default select field
  return (
    <select
      className="w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:ring-0 focus:border-rose-500 bg-white"
      value={value || ""}
      onChange={onChange}
      required={required}
    >
      <option value="">{t(`sections.${field.sectionId}.fields.${field.id}.placeholder`)}</option>
      {field.options.map((option) => (
        <option key={option.value} value={option.value}>
          {t(`sections.${field.sectionId}.fields.${field.id}.options.${option.value}`)}
        </option>
      ))}
    </select>
  );
};
// FormBuilder Component - Main Component Setup and Helper Functions
const FormBuilder = ({ formData = {}, onFormChange, selectedLanguage, sections }) => {
  // State and Hooks
  const [isTemplateCardOpen, setIsTemplateCardOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('default');
  const [expandedSections, setExpandedSections] = useState({
    personalDetails: true,
  });

  const [customSectionTitles, setCustomSectionTitles] = useState(() => {
    const savedTitles = localStorage.getItem('customSectionTitles');
    return savedTitles ? JSON.parse(savedTitles) : {};
  });

  const [customLabels, setCustomLabels] = useState({});
  const { clearBiodata } = useBiodataStorage();
  const originalSections = useMemo(() => JSON.parse(JSON.stringify(sections)), [sections]);
  const t = (key) => getTranslation(selectedLanguage, key);

  // Responsive style classes
  const sectionClass = "bg-white border-b border-gray-200 w-full";
  const sectionHeaderClass = "flex justify-between items-center cursor-pointer p-4 md:p-6 bg-gray-50 rounded-lg mb-4";
  const sectionTitleClass = "text-lg md:text-xl font-semibold text-gray-800";
  const fieldContainerClass = "flex flex-col md:flex-row items-start md:items-center gap-4 bg-gray-50 p-4 rounded-lg";
  const labelContainerClass = "w-full md:w-[185px] flex-shrink-0";
  const inputContainerClass = "w-full flex-1";
  const controlsContainerClass = "flex items-center gap-1 justify-end md:justify-start mt-3 md:mt-0"; 
  const buttonContainerClass = "flex flex-col sm:flex-row items-stretch sm:items-center gap-4 mt-8 px-4 md:px-6 mb-6";

  // Load custom labels on component mount
  useEffect(() => {
    const savedLabels = localStorage.getItem('customLabels');
    if (savedLabels) {
      try {
        setCustomLabels(JSON.parse(savedLabels));
      } catch (error) {
        console.error('Error loading custom labels:', error);
      }
    }
  }, []);

  // Toggle section expansion
  const toggleSection = (sectionId) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  };

  // Handle label saving
  const handleLabelSave = (sectionId, fieldId, newLabel) => {
    const labelKey = `${sectionId}.${fieldId}`;
    const updatedLabels = {
      ...customLabels,
      [labelKey]: newLabel
    };
    setCustomLabels(updatedLabels);
    localStorage.setItem('customLabels', JSON.stringify(updatedLabels));
  
    const updatedSections = sections.map(section => {
      if (section.id === sectionId) {
        return {
          ...section,
          fields: section.fields.map(field => {
            if (field.id === fieldId) {
              return {
                ...field,
                placeholder: `Enter ${newLabel}`,
                label: newLabel
              };
            }
            return field;
          })
        };
      }
      return section;
    });
  
    onFormChange(formData, updatedSections, updatedLabels);
  };

  const handleSectionTitleSave = (sectionId, newTitle) => {
    // Update local state
    const updatedTitles = {
      ...customSectionTitles,
      [sectionId]: newTitle
    };
    setCustomSectionTitles(updatedTitles);
    localStorage.setItem('customSectionTitles', JSON.stringify(updatedTitles));
  
    // Create a deep copy of sections
    const updatedSections = sections.map(section => {
      if (section.id === sectionId) {
        return {
          ...section,
          title: newTitle
        };
      }
      return {...section};
    });
  
    // Pass the updated sections to parent
    onFormChange(formData, updatedSections, customLabels);
  };

  const getSectionTitle = (sectionId) => {
    return customSectionTitles[sectionId] || t(`sections.${sectionId}.title`);
  };

  // Get field label
  const getFieldLabel = (sectionId, fieldId) => {
    const customLabel = customLabels[`${sectionId}.${fieldId}`];
    if (fieldId.startsWith('custom_')) {
      const field = sections
        .find(s => s.id === sectionId)
        ?.fields.find(f => f.id === fieldId);
      return customLabel || field?.label || '';
    }
    return customLabel || t(`sections.${sectionId}.fields.${fieldId}.label`);
  };

  // Move field up or down
  const moveField = (sectionId, fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex === sections.find(s => s.id === sectionId).fields.length) return;
    
    const newSections = sections.map(section => {
      if (section.id === sectionId) {
        const newFields = [...section.fields];
        const [movedField] = newFields.splice(fromIndex, 1);
        newFields.splice(toIndex, 0, movedField);
        
        const newSectionData = {};
        newFields.forEach(field => {
          if (formData[sectionId] && formData[sectionId][field.id] !== undefined) {
            newSectionData[field.id] = formData[sectionId][field.id];
          }
        });
        
        onFormChange(
          {
            ...formData,
            [sectionId]: newSectionData
          },
          sections.map(s => s.id === sectionId ? { ...section, fields: newFields } : s)
        );
        
        return { ...section, fields: newFields };
      }
      return section;
    });
  };
  // Remove field
  const removeField = (sectionId, fieldId) => {
    const field = sections
      .find(section => section.id === sectionId)
      ?.fields.find(f => f.id === fieldId);

    if (field?.required) return;

    const newSections = sections.map(section =>
      section.id === sectionId
        ? {
            ...section,
            fields: section.fields.filter(field => field.id !== fieldId)
          }
        : section
    );

    if (formData[sectionId]?.[fieldId]) {
      const newFormData = { ...formData };
      delete newFormData[sectionId][fieldId];
      onFormChange(newFormData, newSections);
    } else {
      onFormChange(formData, newSections);
    }
  };

  // Handle field value changes
  const handleFieldValueChange = (sectionId, fieldId, value) => {
    const updatedFormData = {
      ...formData,
      [sectionId]: {
        ...(formData[sectionId] || {}),
        [fieldId]: value
      }
    };
    
    if (fieldId.startsWith('custom_')) {
      const sectionIndex = sections.findIndex(s => s.id === sectionId);
      if (sectionIndex !== -1) {
        const fieldIndex = sections[sectionIndex].fields.findIndex(f => f.id === fieldId);
        if (fieldIndex === -1) {
          sections[sectionIndex].fields.push({
            id: fieldId,
            label: `Custom Field`,
            type: 'text',
            required: false
          });
        }
      }
    }
    
    onFormChange(updatedFormData, sections);
  };

  // Handle god image selection
  const handleGodImageSelect = (imageName) => {
    handleFieldValueChange('customization', 'godImage', imageName);
  };

  // Handle template selection
  const handleTemplateSelect = (templateId) => {
    console.log(`Selected template: ${templateId}`);
    setIsTemplateCardOpen(false);
  };

  // Parse time value helper function
  const parseTimeValue = (value) => {
    if (!value) return { hours: '', minutes: '', period: 'AM' };
    const [timeStr] = value.split(':');
    const hours = parseInt(timeStr);
    const minutes = value.split(':')[1];
    const period = hours >= 12 ? 'PM' : 'AM';
    const displayHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
    return {
      hours: displayHours.toString(),
      minutes: minutes,
      period: period
    };
  };

  // Render input based on field type
  const renderInput = (field, sectionId) => {
    const value = formData[sectionId]?.[field.id] || '';
    field.sectionId = sectionId;
    
    const inputBaseClass = "w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:ring-0 focus:border-rose-500";
    const placeholder = field.id.startsWith('custom_') 
      ? `Enter ${getFieldLabel(sectionId, field.id)}`
      : t(`sections.${sectionId}.fields.${field.id}.placeholder`);

    // Special handling for Customization section
    if (sectionId === 'customization') {
      switch (field.id) {
        case 'mantra':
        case 'biodataTitle':
          return (
            <input
              type="text"
              className={inputBaseClass}
              placeholder={t(`sections.${sectionId}.fields.${field.id}.placeholder`)}
              value={value}
              onChange={(e) => handleFieldValueChange(sectionId, field.id, e.target.value)}
            />
          );
          case 'godImage':
  return (
    <div className="grid grid-cols-4 gap-3">
      {[
        'ganesha1.png', 'ganesha2.png', 'ganesha3.png', 'ganesha4.png',
        'shree1.png', 'shree2.png', 'shree3.png', 'shree4.png',
        'om1.png', 'om2.png', 'om3.png', 'om4.png',
        'buddha1.png', 'buddha2.png', 'buddha3.png', 'buddha4.png',
        'islam1.png', 'islam2.png', 'islam3.png', 'islam4.png'
      ].map((image) => (
        <div 
          key={image}
          className={`cursor-pointer relative p-1.5 rounded-lg overflow-hidden border-2 hover:border-rose-400 transition-all duration-200 ${
            value === image ? 'border-rose-500 bg-rose-50' : 'border-gray-200'
          }`}
          onClick={() => handleGodImageSelect(image)}
        >
          <div className="aspect-square">
            <img
              src={`/images/gods/${image}`}
              alt={image.split('.')[0]}
              className="w-full h-full object-contain rounded-lg"
              onError={(e) => {
                e.target.src = '/images/gods/ganesha1.png';
                e.target.onerror = null;
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
        default:
          return null;
      }
    }
    // Handle other input types
    switch (field.type) {
      case 'select':
        return (
          <CustomSelect
            field={field}
            value={value}
            onChange={(e) => handleFieldValueChange(sectionId, field.id, e.target.value)}
            required={field.required}
            selectedLanguage={selectedLanguage}
          />
        );

      case 'textarea':
        return (
          <textarea
            className={`${inputBaseClass} min-h-[100px]`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleFieldValueChange(sectionId, field.id, e.target.value)}
            rows={4}
            required={field.required}
          />
        );

      case 'image':
        return (
          <div className="w-full">
            <ImageUploader
              value={value}
              onChange={(newValue) => handleFieldValueChange(sectionId, field.id, newValue)}
              selectedLanguage={selectedLanguage}
            />
          </div>
        );

      case 'time': {
        const { hours, minutes, period } = parseTimeValue(value);
        return (
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 w-full">
            <select 
              className={inputBaseClass}
              value={hours}
              onChange={(e) => {
                const newHours = e.target.value;
                const militaryHours = period === 'PM' && newHours !== '12' 
                  ? parseInt(newHours) + 12 
                  : (period === 'AM' && newHours === '12' ? 0 : newHours);
                const newTime = `${militaryHours.toString().padStart(2, '0')}:${minutes || '00'}`;
                handleFieldValueChange(sectionId, field.id, newTime);
              }}
            >
              <option value="">Hour</option>
              {[...Array(12)].map((_, i) => (
                <option key={i + 1} value={(i + 1).toString()}>
                  {i + 1}
                </option>
              ))}
            </select>

            <select
              className={inputBaseClass}
              value={minutes}
              onChange={(e) => {
                const newMinutes = e.target.value;
                const militaryHours = period === 'PM' && hours !== '12'
                  ? parseInt(hours) + 12
                  : (period === 'AM' && hours === '12' ? 0 : hours);
                const newTime = `${militaryHours.toString().padStart(2, '0')}:${newMinutes}`;
                handleFieldValueChange(sectionId, field.id, newTime);
              }}
            >
              <option value="">Min</option>
              {[...Array(60)].map((_, i) => (
                <option key={i} value={i.toString().padStart(2, '0')}>
                  {i.toString().padStart(2, '0')}
                </option>
              ))}
            </select>

            <select
              className={inputBaseClass}
              value={period}
              onChange={(e) => {
                const newPeriod = e.target.value;
                let militaryHours = parseInt(hours);
                if (newPeriod === 'PM' && hours !== '12') {
                  militaryHours += 12;
                } else if (newPeriod === 'AM' && hours === '12') {
                  militaryHours = 0;
                }
                const newTime = `${militaryHours.toString().padStart(2, '0')}:${minutes || '00'}`;
                handleFieldValueChange(sectionId, field.id, newTime);
              }}
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </div>
        );
      }

      case 'tel':
        return (
          <input
            type="tel"
            className={inputBaseClass}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              const phoneNumber = e.target.value.replace(/\D/g, '').slice(0, 10);
              handleFieldValueChange(sectionId, field.id, phoneNumber);
            }}
            pattern="[0-9]{10}"
            maxLength="10"
            required={field.required}
          />
        );

      default:
        return (
          <input
            type={field.type}
            className={inputBaseClass}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleFieldValueChange(sectionId, field.id, e.target.value)}
            required={field.required}
          />
        );
    }
  };

  // Main render return
  return (
    <div className="space-y-4 max-w-full overflow-x-hidden">
      {sections.map((section, sectionIndex) => (
  <div key={section.id} className="bg-white w-full max-w-full">
  <div 
    className="flex justify-between items-center p-4 md:p-6 bg-gray-50 rounded-lg hover:cursor-pointer select-none"
    onClick={(e) => {
      const isLabelClick = e.target.closest('.editable-label') || 
                          e.target.classList.contains('editable-label');
      if (!isLabelClick) {
        toggleSection(section.id);
      }
    }}
  >
    <div className="flex-1 flex items-center">
      <EditableLabel
        initialValue={getSectionTitle(section.id)}
        onSave={(newTitle) => handleSectionTitleSave(section.id, newTitle)}
        className="text-lg md:text-xl font-semibold text-gray-800 editable-label hover:cursor-text"
      />
    </div>
    <div 
      className="w-8 h-8 rounded-full flex items-center justify-center text-gray-500 hover:text-white bg-white hover:bg-rose-400 transition-colors duration-200"
    >
      {expandedSections[section.id] ? 
        <FaChevronUp className="w-4 h-4" /> : 
        <FaChevronDown className="w-4 h-4" />
      }
    </div>
  </div>
 
   {expandedSections[section.id] && (
     <div className="relative">
        <div className="space-y-4 mt-4">
         {section.fields.map((field, index) => (
         <div key={field.id} className="flex flex-col md:flex-row items-start md:items-center gap-4 bg-gray-50 px-4 md:px-6 py-4 rounded-lg">
             {/* Label and Controls in one row for mobile */}
             <div className="w-full md:w-[165px] flex-shrink-0 flex items-center justify-between">
               <div>
                 <EditableLabel
                   initialValue={getFieldLabel(section.id, field.id)}
                   onSave={(newLabel) => handleLabelSave(section.id, field.id, newLabel)}
                   className="text-sm md:text-base font-medium text-gray-700"
                 />
                 {field.required && <span className="text-rose-500 ml-1">*</span>}
               </div>
 
               {/* Control buttons - now inline with label on mobile */}
               {section.id !== 'customization' && section.id !== 'photoUpload' && (
                 <div className="flex items-center gap-1 md:hidden">
                   <button
                     onClick={() => moveField(section.id, index, index - 1)}
                     disabled={index === 0}
                     className={`p-1 rounded-lg ${
                       index === 0 
                         ? 'text-gray-300 cursor-not-allowed' 
                         : 'text-gray-500 hover:text-rose-600 hover:bg-rose-50'
                     }`}
                   >
                     <FaArrowUp className="w-3 h-3" />
                   </button>
 
                   <button
                     onClick={() => moveField(section.id, index, index + 1)}
                     disabled={index === section.fields.length - 1}
                     className={`p-1 rounded-lg ${
                       index === section.fields.length - 1
                         ? 'text-gray-300 cursor-not-allowed'
                         : 'text-gray-500 hover:text-rose-600 hover:bg-rose-50'
                     }`}
                   >
                     <FaArrowDown className="w-3 h-3" />
                   </button>
 
                   <button
                     onClick={() => removeField(section.id, field.id)}
                     disabled={field.required}
                     className={`p-1 rounded-lg ${
                       field.required
                         ? 'text-gray-300 cursor-not-allowed'
                         : 'text-gray-500 hover:text-red-600 hover:bg-red-50'
                     }`}
                   >
                     <FaTrash className="w-3 h-3" />
                   </button>
                 </div>
               )}
             </div>
             
             <div className="flex-1 w-full">
               {renderInput(field, section.id)}
             </div>
 
             {/* Control buttons for desktop view */}
             {section.id !== 'customization' && section.id !== 'photoUpload' && (
               <div className="hidden md:flex items-center gap-0 justify-end md:justify-start mt-3 md:mt-0">
                 <button
                   onClick={() => moveField(section.id, index, index - 1)}
                   disabled={index === 0}
                   className={`p-1 rounded-lg ${
                     index === 0 
                       ? 'text-gray-300 cursor-not-allowed' 
                       : 'text-gray-500 hover:text-rose-600 hover:bg-rose-50'
                   }`}
                 >
                   <FaArrowUp className="w-4 h-4" />
                 </button>
 
                 <button
                   onClick={() => moveField(section.id, index, index + 1)}
                   disabled={index === section.fields.length - 1}
                   className={`p-1 rounded-lg ${
                     index === section.fields.length - 1
                       ? 'text-gray-300 cursor-not-allowed'
                       : 'text-gray-500 hover:text-rose-600 hover:bg-rose-50'
                   }`}
                 >
                   <FaArrowDown className="w-4 h-4" />
                 </button>
 
                 <button
                   onClick={() => removeField(section.id, field.id)}
                   disabled={field.required}
                   className={`p-1 rounded-lg ${
                     field.required
                       ? 'text-gray-300 cursor-not-allowed'
                       : 'text-gray-500 hover:text-red-600 hover:bg-red-50'
                   }`}
                 >
                   <FaTrash className="w-4 h-4" />
                 </button>
               </div>
             )}
           </div>
         ))}
  
                {section.id !== 'customization' && section.id !== 'photoUpload' && (
                  <button
                    onClick={() => {
                      const fieldNumber = section.fields.length + 1;
                      const newField = {
                        id: `custom_${Date.now()}`,
                        label: `New Field ${fieldNumber}`,
                        type: 'text',
                        required: false,
                        sectionId: section.id
                      };
                      
                      const updatedSections = sections.map(s => {
                        if (s.id === section.id) {
                          return {
                            ...s,
                            fields: [...s.fields, newField]
                          };
                        }
                        return s;
                      });
                      
                      onFormChange(formData, updatedSections);
                    }}
                    className="w-full sm:w-auto mt-4 px-4 py-2 text-rose-500 hover:text-rose-600 font-medium flex items-center gap-2 justify-center"
                  >
                    <FaPlus className="w-4 h-4" />
                    {t('addNewField')}
                  </button>
                )}
                
                {/* Horizontal line - only shown when section is expanded */}
                <div className="pt-4 border-b border-gray-200"></div>
              </div>
            </div>
          )}
        </div>
      ))}
  
  <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 mt-8 px-4 md:px-6 mb-6 max-w-full">
  <button 
  className="w-full sm:w-[35%] bg-gray-600 hover:bg-gray-800 text-white font-medium py-2 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center gap-2"
  onClick={() => {
    onFormChange({}, originalSections);
    clearBiodata();
    setExpandedSections({ personalDetails: true });
    localStorage.removeItem('customLabels');
    localStorage.removeItem('customSectionTitles'); // Add this line
    setCustomSectionTitles({}); // Add this line
  }}
>
          <FaTrash className="w-4 h-4" />
          {t('reset')}
        </button>
  
        <button 
          className="w-full bg-rose-500 hover:bg-rose-600 text-white font-medium py-2 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center gap-2"
          onClick={() => setIsTemplateCardOpen(true)}
        >
          <FaFileAlt className="w-4 h-4" />
          {t('chooseTemplate')}
        </button>
      </div>
  
      {isTemplateCardOpen && (
        <TemplateSelector 
          isOpen={isTemplateCardOpen}
          onClose={() => setIsTemplateCardOpen(false)}
          formData={formData}
          selectedLanguage={selectedLanguage}
          sections={sections}
          customLabels={customLabels}
        />
      )}
    </div>
  );
};

export default FormBuilder;