import React from 'react';
import { FaLanguage, FaRegEdit, FaMagic, FaDownload } from 'react-icons/fa';

const FeatureCard = ({ feature }) => (
  <div 
    className="group relative rounded-xl overflow-hidden
      transition-all duration-300 ease-in-out p-6
      bg-white shadow-lg border border-gray-100
      hover:shadow-xl hover:scale-[1.02]"
  >
    <div className="flex flex-col h-full">
      <div className="flex items-center space-x-4 mb-5">
        <div className="w-12 h-12 rounded-xl flex items-center justify-center bg-gradient-to-br from-rose-100 to-rose-50 shadow-sm">
          <feature.icon className="w-6 h-6 text-rose-500" />
        </div>
        <h3 className="text-xl font-semibold text-gray-800 tracking-tight">
          {feature.title}
        </h3>
      </div>

      <p className="text-gray-600 text-lg leading-relaxed">
        {feature.description}
      </p>
    </div>
    
    <div className="absolute inset-0 bg-gradient-to-br from-rose-500/0 to-rose-500/0 group-hover:from-rose-500/5 group-hover:to-rose-500/0 transition-colors duration-300" />
  </div>
);

const BiodataAppFeatures = () => {
  const features = [
    {
      icon: FaLanguage,
      title: "Multi-Language Support",
      description: "Create your biodata in English, Hindi, Marathi, Gujarati, or Kannada with real-time language switching."
    },
    {
      icon: FaMagic,
      title: "Smart Templates",
      description: "Choose from professionally designed templates with modern layouts and customizable formats."
    },
    {
      icon: FaRegEdit,
      title: "Easy Customization",
      description: "Personalize your biodata, including photo, content, and new fields to create a unique profile."
    },
    {
      icon: FaDownload,
      title: "Smart Export Options",
      description: "Get high-quality PDFs & Images optimized for both digital sharing and printing."
    }
  ];

  return (
    <div className="bg-gradient-to-b from-rose-50 via-rose-100/30 to-white">
      <div className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="mb-12 text-center px-4">
            <span className="text-rose-500 font-medium text-lg mb-4 block">
              Powerful Features
            </span>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4 tracking-tight">
              Matrimonial Biodata Maker
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto text-lg md:text-xl leading-relaxed">
              Create the perfect biodata with our comprehensive suite of features
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-4">
            {features.map((feature, index) => (
              <FeatureCard key={index} feature={feature} />
            ))}
          </div>

          <div className="mt-8 text-center">
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiodataAppFeatures;