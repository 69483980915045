// translations.js

export const translations = {
     
  english: {
  addNewField: "Add New Field",
  useTemplate: "Use Template",
  downloadNow: "Download Now",
  continueAndDownload: "Continue & Download",
  generating: "Please Wait..",
  selected: "Selected",
  chooseTemplate: "Choose Template",
  selectTemplateDesc: "Select a template design for your biodata",
  template: "Templates",
  preview: "Preview",
  fillBiodataFirst:"No Preview Available",
  fillBiodataDescription: "Fill the information in the form to see your biodata preview here.",

    title: "Create Your Biodata",
    subtitle: "Fill in the details below to generate your biodata",
    preview: "Biodata Preview",
    emptyPreview: {
      
      title: "Your Biodata Preview",
      description: "Start filling out the form on the left to see your biodata preview here in real-time."
    },
    addNewField: "Add New Field",
    chooseTemplate: "Choose Template",
    reset: "Reset",
    form: {
      placeholders: {
        enter: "Enter",
        select: "Select"
      }
    },
    imageUploader: {
      dragDrop: "Drag and drop your photo here or",
      browse: "Browse Files",
      maxSize: "Maximum file size:",
      preview: "Image preview",
      remove: "Remove image",
      fileSizeError: "File size must be less than"
    },

    modal: {
      title: "Download Your Professional Biodata",
      specialOffer: "Special Launch Offer - Save 50% Today",
      description: "Create a lasting first impression with our professionally designed biodata format.",
      sharingInfo: "Perfect for sharing with prospective matches and their families.",
      features: {
        premium: {
          title: "Premium Quality Output",
          description: "High-resolution PDF & PNG ready for both digital sharing and printing"
        },
        secure: {
          title: "Secure Transaction",
          description: "Bank-grade security for your payment and personal information"
        }
      },
      timer: {
        prefix: "Limited time offer expires in:",
        price: {
          original: "₹49",
          discounted: "FREE",
          suffix: "for a few days!"
        }
      },
      downloadButton: {
        default: "Download Premium Biodata",
        loading: "Generating Files..."
      },
      trustBadge: "Trusted by 1,000+ Happy Customers",
      close: "Close"
    },
    
    sections: {
      customization: {
        title: "Customization",
        fields: {
          mantra: {
            label: "Mantra",
            placeholder: "|| Shree Ganeshay Namah ||"
          },
          biodataTitle: {
            label: "Biodata Title",
            placeholder: "Biodata"
          },
          godImage: {
            label: "God Image",
            placeholder: "Upload god image"
          }
        }
      },
        personalDetails: {
          title: "Personal Details",
          fields: {
            name: {
              label: "Name",
              placeholder: "Enter your name"
            },
            dateOfBirth: {
              label: "Date of Birth",
              placeholder: "Select date"
            },
            timeOfBirth: {
              label: "Time of Birth",
              placeholder: "Select time"
            },
            placeOfBirth: {
              label: "Place of Birth",
              placeholder: "Enter place of birth"
            },
            height: {
              label: "Height",
              placeholder: "Select height"
            },
            maritalStatus: {
              label: "Marital Status",
              placeholder: "Select marital status",
              options: {
                single: "Single",
                divorced: "Divorced",
                widowed: "Widowed",
                separated: "Separated"
              }
            },
            complexion: {
              label: "Complexion",
              placeholder: "Select complexion",
              options: {
                very_fair: "Very Fair",
                fair: "Fair",
                wheatish: "Wheatish",
                brown: "Brown",
                dark: "Dark"
              }
            },
            bloodGroup: {
              label: "Blood Group",
              placeholder: "Select blood group",
              options: {
                a_positive: "A+",
                a_negative: "A-",
                b_positive: "B+",
                b_negative: "B-",
                o_positive: "O+",
                o_negative: "O-",
                ab_positive: "AB+",
                ab_negative: "AB-"
              }
            },
            education: {
              label: "Education",
              placeholder: "Enter education details"
            },
            collegeName: {
              label: "College Name",
              placeholder: "Enter college name"
            },
            occupation: {
              label: "Job / Occupation",
              placeholder: "Enter job / occupation"
            },
            organizationName: {
              label: "Organization Name",
              placeholder: "Enter organization name"
            },
            annualIncome: {
              label: "Annual Income",
              placeholder: "Select annual income",
              options: {
                "0-5": "0-5 LPA",
                "5-10": "5-10 LPA",
                "10-15": "10-15 LPA",
                "15-20": "15-20 LPA",
                "20-25": "20-25 LPA",
                "25-30": "25-30 LPA",
                "30-35": "30-35 LPA",
                "35-40": "35-40 LPA",
                "40-45": "40-45 LPA",
                "45-50": "45-50 LPA",
                "50+": "50+ LPA"
              }
            },
            religion: {
              label: "Religion",
              placeholder: "Enter religion"
            },
            motherTongue: {
              label: "Mother Tongue",
              placeholder: "Enter mother tongue"
            },
            caste: {
              label: "Caste",
              placeholder: "Enter caste"
            },
            subCaste: {
              label: "Sub Caste",
              placeholder: "Enter sub caste"
            },

            rashi: {
              label: "Rashi",
              placeholder: "Select Rashi",
              options: {
                mesh: "Mesh (Aries)",
                vrishabha: "Varishabha (Taurus)",
                mithuna: "Mithuna (Gemini)",
                karka: "Karka (Cancer)", 
                simha: "Simha (Leo)",
                kanya: "Kanya (Virgo)",
                tula: "Tula (Libra)", 
                vrischika: "Vrischika (Scorpio)",
                dhanur: "Dhanur (Sagittarious)",
                makara: "Makara (Capricorn)",
                kumbha: "Kumbha (Aquarius)",
                meena: "Meena (Pisces)"
              }
            },
            nakshatra: {
              label: "Nakshatra",
              placeholder: "Select Nakshatra",
              options: {
                ashwini: "Ashwini",
                bharani: "Bharani", 
                krittika: "Krittika",
                rohini: "Rohini",
                mrigashira: "Mrigashira",
                ardra: "Ardra",
                punarvasu: "Punarvasu",
                pushya: "Pushya",
                ashlesha: "Ashlesha",
                magha: "Magha",
                purva_phalguni: "Purva Phalguni",
                uttara_phalguni: "Uttara Phalguni",
                hasta: "Hasta",
                chitra: "Chitra",
                swati: "Swati",
                vishakha: "Vishakha",
                anuradha: "Anuradha",
                jyeshtha: "Jyeshtha",
                mula: "Mula",
                purva_ashadha: "Purva Ashadha",
                uttara_ashadha: "Uttara Ashadha", 
                shravana: "Shravana",
                dhanishta: "Dhanishta",
                shatabhisha: "Shatabhisha",
                purva_bhadrapada: "Purva Bhadrapada",
                uttara_bhadrapada: "Uttara Bhadrapada",
                revati: "Revati"
              }
            },
            gotra: {
              label: "Gotra",
              placeholder: "Enter gotra"
            },
            manglik: {
              label: "Manglik",
              placeholder: "Select manglik status",
              options: {
                no: "No",
                yes: "Yes",
                partial: "Partial (Anshik)"
              }
            }
          }
        },
        familyDetails: {
          title: "Family Details",
          fields: {
            fatherName: {
              label: "Father's Name",
              placeholder: "Enter father's name"
            },
            fatherOccupation: {
              label: "Father's Occupation",
              placeholder: "Enter father's occupation"
            },
            fatherOrganization: {
              label: "Father's Organization",
              placeholder: "Enter father's organization"
            },
            motherName: {
              label: "Mother's Name",
              placeholder: "Enter mother's name"
            },
            motherOccupation: {
              label: "Mother's Occupation",
              placeholder: "Enter mother's occupation"
            },
            motherOrganization: {
              label: "Mother's Organization",
              placeholder: "Enter mother's organization"
            },
          
            brothersMarried: {
              label: "Married Brothers",
              placeholder: "Enter number"
            },
            totalBrothers: {
              label: "Total Brothers",
              placeholder: "Enter number"
            },
            sistersMarried: {
              label: "Married Sisters",
              placeholder: "Enter number"
            },
            totalSisters: {
              label: "Total Sisters",
              placeholder: "Enter number"
            },
            familyType: {
              label: "Family Type",
              placeholder: "Select family type",
              options: {
                nuclear: "Nuclear Family",
                joint: "Joint Family",
                extended: "Extended Family"
              }
            },
            familyValues: {
              label: "Family Values",
              placeholder: "Select family values",
              options: {
                traditional: "Traditional",
                moderate: "Moderate",
                liberal: "Liberal"
              }
            },
            familyStatus: {
              label: "Family Status",
              placeholder: "Select family status",
              options: {
                middle_class: "Middle Class",
                upper_middle_class: "Upper Middle Class",
                rich: "Rich",
                affluent: "Affluent"
              }
            },
            nativePlace: {
              label: "Native Place",
              placeholder: "Enter native place"
            }
          }
        },
        contactDetails: {
          title: "Contact Details",
          fields: {
            address: {
              label: "Residential Address",
              placeholder: "Enter residential address"
            },
            landmark: {
              label: "Landmark",
              placeholder: "Enter landmark"
            },
            city: {
              label: "City",
              placeholder: "Enter city"
            },
            state: {
              label: "State",
              placeholder: "Enter state"
            },
            pinCode: {
              label: "Pin Code",
              placeholder: "Enter pin code"
            },
            phone: {
              label: "Phone Number",
              placeholder: "Enter phone number"
            },
            alternatePhone: {
              label: "Alternate Phone",
              placeholder: "Enter alternate phone"
            },
            email: {
              label: "Email Address",
              placeholder: "Enter email address"
            },
            alternateEmail: {
              label: "Alternate Email",
              placeholder: "Enter alternate email"
            }
          }
        },
   
        
        photoUpload: {
          title: "Add Your Photo",
          fields: {
            profilePhoto: {
              label: "Profile Photo",
              placeholder: "Upload profile photo"
            }
          }
        }
      }
      
    },

    

    // Part 3 - Hindi Translations
hindi: {


  useTemplate: "टेम्पलेट उपयोग करें",
  downloadNow: "डाउनलोड करें",
  continueAndDownload: "जारी रखें और डाउनलोड करें",
  generating: "कृपया प्रतीक्षा करें..",
  chooseTemplate: "टेम्पलेट चुनें",
  reset: "रीसेट करें",
  selected: "चयनित",
  selectTemplateDesc: "अपने बायोडाटा के लिए टेम्पलेट डिज़ाइन चुनें",
  template: "टेम्पलेट",
  preview: "पूर्वावलोकन",
  fillBiodataFirst:"कोई पूर्वावलोकन उपलब्ध नहीं है",
  fillBiodataDescription: "यहां अपना बायोडाटा पूर्वावलोकन देखने के लिए फॉर्म में जानकारी भरें.",



      title: "अपना बायोडाटा बनाएं",
      subtitle: "अपना बायोडाटा जनरेट करने के लिए नीचे विवरण भरें",
      preview: "बायोडाटा पूर्वावलोकन",
      emptyPreview: {
        title: "आपका बायोडाटा पूर्वावलोकन",
        description: "रीयल-टाइम में अपना बायोडाटा पूर्वावलोकन देखने के लिए बाईं ओर फॉर्म भरना शुरू करें।"
      },
      addNewField: "नया फ़ील्ड जोड़ें",
      chooseTemplate: "टेम्पलेट चुनें",
      
      reset: "रीसेट करें",
      form: {
        placeholders: {
          enter: "दर्ज करें",
          select: "चुनें"
        }
      },
      imageUploader: {
        dragDrop: "यहां अपनी फोटो खींचें और छोड़ें या",
        browse: "फ़ाइलें चुनें",
        maxSize: "अधिकतम फ़ाइल साइज़:",
        preview: "छवि पूर्वावलोकन",
        remove: "छवि हटाएं",
        fileSizeError: "फ़ाइल का आकार इससे कम होना चाहिए"
      },
      modal: {
        title: "अपना प्रोफेशनल बायोडाटा डाउनलोड करें",
        specialOffer: "स्पेशल लॉन्च ऑफर - आज 50% की बचत करें",
        description: "हमारे प्रोफेशनली डिज़ाइन किए गए बायोडाटा फॉर्मेट से एक स्थायी पहली छाप बनाएं।",
        sharingInfo: "संभावित रिश्तों और उनके परिवारों के साथ साझा करने के लिए बिल्कुल सही।",
        features: {
          premium: {
            title: "प्रीमियम क्वालिटी आउटपुट",
            description: "डिजिटल शेयरिंग और प्रिंटिंग दोनों के लिए उच्च रेजोल्यूशन PDF और PNG"
          },
          secure: {
            title: "सुरक्षित लेनदेन",
            description: "आपके भुगतान और व्यक्तिगत जानकारी के लिए बैंक-ग्रेड सुरक्षा"
          }
        },
        timer: {
          prefix: "सीमित समय का ऑफर समाप्त हो रहा है:",
          price: {
            original: "₹49",
            discounted: "FREE",
            suffix: "मात्र"
          }
        },
        downloadButton: {
          default: "प्रीमियम बायोडाटा डाउनलोड करें",
          loading: "फाइल्स जनरेट हो रही हैं..."
        },
        trustBadge: "1,000+ संतुष्ट ग्राहकों द्वारा विश्वसनीय",
        close: "बंद करें"
      },
      
      sections: {
        personalDetails: {
          title: "व्यक्तिगत विवरण",
          fields: {
            name: {
              label: "नाम",
              placeholder: "अपना नाम दर्ज करें"
            },
            dateOfBirth: {
              label: "जन्म तिथि",
              placeholder: "जन्म तिथि चुनें"
            },
            timeOfBirth: {
              label: "जन्म समय",
              placeholder: "जन्म समय चुनें"
            },
            placeOfBirth: {
              label: "जन्म स्थान",
              placeholder: "जन्म स्थान दर्ज करें"
            },
            height: {
              label: "कद",
              placeholder: "कद चुनें"
            },
            maritalStatus: {
              label: "वैवाहिक स्थिति",
              placeholder: "वैवाहिक स्थिति चुनें",
              options: {
                single: "अविवाहित",
                divorced: "तलाकशुदा",
                widowed: "विधवा/विधुर",
                separated: "अलग"
              }
            },
            complexion: {
              label: "रंग",
              placeholder: "रंग चुनें",
              options: {
                very_fair: "बहुत गोरा",
                fair: "गोरा",
                wheatish: "गेहुआं",
                brown: "भूरा",
                dark: "सांवला"
              }
            },
            bloodGroup: {
              label: "रक्त समूह",
              placeholder: "रक्त समूह चुनें",
              options: {
                a_positive: "A+",
                a_negative: "A-",
                b_positive: "B+",
                b_negative: "B-",
                o_positive: "O+",
                o_negative: "O-",
                ab_positive: "AB+",
                ab_negative: "AB-"
              }
            },
            education: {
              label: "शिक्षा",
              placeholder: "शिक्षा विवरण दर्ज करें"
            },
            collegeName: {
              label: "कॉलेज का नाम",
              placeholder: "कॉलेज का नाम दर्ज करें"
            },
            occupation: {
              label: "व्यवसाय",
              placeholder: "व्यवसाय दर्ज करें"
            },
            organizationName: {
              label: "संगठन का नाम",
              placeholder: "संगठन का नाम दर्ज करें"
            },
            annualIncome: {
              label: "वार्षिक आय",
              placeholder: "वार्षिक आय चुनें",
              options: {
                "0-5": "0-5 लाख",
                "5-10": "5-10 लाख",
                "10-15": "10-15 लाख",
                "15-20": "15-20 लाख",
                "20-25": "20-25 लाख",
                "25-30": "25-30 लाख",
                "30-35": "30-35 लाख",
                "35-40": "35-40 लाख",
                "40-45": "40-45 लाख",
                "45-50": "45-50 लाख",
                "50+": "50+ लाख"
              }
            },
            religion: {
              label: "धर्म",
              placeholder: "धर्म दर्ज करें"
            },
            motherTongue: {
              label: "मातृभाषा",
              placeholder: "मातृभाषा दर्ज करें"
            },
            caste: {
              label: "जाति",
              placeholder: "जाति दर्ज करें"
            },
            subCaste: {
              label: "उपजाति",
              placeholder: "उपजाति दर्ज करें"
            },
            rashi: {
              label: "राशि",
              placeholder: "राशि चुनें",
              options: {
                mesh: "मेष (Aries)",
                vrishabha: "वृषभ (Taurus)",
                mithuna: "मिथुन (Gemini)",
                karka: "कर्क (Cancer)",
                simha: "सिंह (Leo)",
                kanya: "कन्या (Virgo)", 
                tula: "तुला (Libra)",
                vrischika: "वृश्चिक (Scorpio)",
                dhanur: "धनु (Sagittarious)",
                makara: "मकर (Capricorn)",
                kumbha: "कुंभ (Aquarius)",
                meena: "मीन (Pisces)"
              }
            },
            nakshatra: {
              label: "नक्षत्र",
              placeholder: "नक्षत्र चुनें",
              options: {
                ashwini: "अश्विनी",
                bharani: "भरणी",
                krittika: "कृत्तिका",
                rohini: "रोहिणी",
                mrigashira: "मृगशिरा",
                ardra: "आर्द्रा",
                punarvasu: "पुनर्वसु",
                pushya: "पुष्य",
                ashlesha: "आश्लेषा",
                magha: "मघा",
                purva_phalguni: "पूर्व फाल्गुनी",
                uttara_phalguni: "उत्तर फाल्गुनी",
                hasta: "हस्त",
                chitra: "चित्रा",
                swati: "स्वाति",
                vishakha: "विशाखा",
                anuradha: "अनुराधा",
                jyeshtha: "ज्येष्ठा",
                mula: "मूल",
                purva_ashadha: "पूर्वाषाढ़ा",
                uttara_ashadha: "उत्तराषाढ़ा",
                shravana: "श्रवण",
                dhanishta: "धनिष्ठा",
                shatabhisha: "शतभिषा",
                purva_bhadrapada: "पूर्व भाद्रपद",
                uttara_bhadrapada: "उत्तर भाद्रपद",
                revati: "रेवती"
              }
            },
            gotra: {
              label: "गोत्र",
              placeholder: "गोत्र दर्ज करें"
            },
            manglik: {
              label: "मांगलिक",
              placeholder: "मांगलिक स्थिति चुनें",
              options: {
                no: "नहीं",
                yes: "हाँ",
                partial: "आंशिक"
              }
            }
          }
        },
        familyDetails: {
          title: "पारिवारिक विवरण",
          fields: {
            fatherName: {
              label: "पिताजी का नाम",
              placeholder: "पिताजी का नाम दर्ज करें"
            },
            fatherOccupation: {
              label: "पिताजी का व्यवसाय",
              placeholder: "पिताजी का व्यवसाय दर्ज करें"
            },
            fatherOrganization: {
              label: "पिताजी का संगठन",
              placeholder: "पिताजी के संगठन का नाम दर्ज करें"
            },
            motherName: {
              label: "माताजी का नाम",
              placeholder: "माताजी का नाम दर्ज करें"
            },
            motherOccupation: {
              label: "माताजी का व्यवसाय",
              placeholder: "माताजी का व्यवसाय दर्ज करें"
            },
            motherOrganization: {
              label: "माताजी का संगठन",
              placeholder: "माताजी के संगठन का नाम दर्ज करें"
            },
            siblings: {
              label: "भाई-बहनों की संख्या",
              placeholder: "भाई-बहनों की संख्या दर्ज करें"
            },
            brothersMarried: {
              label: "विवाहित भाई",
              placeholder: "संख्या दर्ज करें"
            },
            brothersUnmarried: {
              label: "अविवाहित भाई",
              placeholder: "संख्या दर्ज करें"
            },
            sistersMarried: {
              label: "विवाहित बहनें",
              placeholder: "संख्या दर्ज करें"
            },
            sistersUnmarried: {
              label: "अविवाहित बहनें",
              placeholder: "संख्या दर्ज करें"
            },
            familyType: {
              label: "परिवार का प्रकार",
              placeholder: "परिवार का प्रकार चुनें",
              options: {
                nuclear: "एकल परिवार",
                joint: "संयुक्त परिवार",
                extended: "विस्तृत परिवार"
              }
            },
            familyValues: {
              label: "पारिवारिक मूल्य",
              placeholder: "पारिवारिक मूल्य चुनें",
              options: {
                traditional: "पारंपरिक",
                moderate: "मध्यम",
                liberal: "उदार"
              }
            },
            familyStatus: {
              label: "पारिवारिक स्थिति",
              placeholder: "पारिवारिक स्थिति चुनें",
              options: {
                middle_class: "मध्यम वर्ग",
                upper_middle_class: "उच्च मध्यम वर्ग",
                rich: "धनी",
                affluent: "संपन्न"
              }
            },
            nativePlace: {
              label: "मूल निवास",
              placeholder: "मूल निवास दर्ज करें"
            }
          }
        },
        contactDetails: {
          title: "संपर्क विवरण",
          fields: {
            address: {
              label: "आवासीय पता",
              placeholder: "आवासीय पता दर्ज करें"
            },
            landmark: {
              label: "लैंडमार्क",
              placeholder: "लैंडमार्क दर्ज करें"
            },
            city: {
              label: "शहर",
              placeholder: "शहर दर्ज करें"
            },
            state: {
              label: "राज्य",
              placeholder: "राज्य दर्ज करें"
            },
            pinCode: {
              label: "पिन कोड",
              placeholder: "पिन कोड दर्ज करें"
            },
            phone: {
              label: "फोन नंबर",
              placeholder: "फोन नंबर दर्ज करें"
            },
            alternatePhone: {
              label: "वैकल्पिक फोन",
              placeholder: "वैकल्पिक फोन नंबर दर्ज करें"
            },
            email: {
              label: "ईमेल पता",
              placeholder: "ईमेल पता दर्ज करें"
            },
            alternateEmail: {
              label: "वैकल्पिक ईमेल",
              placeholder: "वैकल्पिक ईमेल पता दर्ज करें"
            }
          }
        },
        photoUpload: {
          title: "अपना फोटो जोड़ें",
          fields: {
            profilePhoto: {
              label: "प्रोफ़ाइल फ़ोटो",
              placeholder: "प्रोफ़ाइल फ़ोटो अपलोड करें"
            }
          }
        },
        customization: {
          title: "कस्टमाइज़ेशन",
          fields: {
            mantra: {
              label: "मंत्र",
              placeholder: "॥ श्री गणेशाय नमः ॥"
            },
            biodataTitle: {
              label: "बायोडाटा शीर्षक",
          placeholder: "बायोडाटा"
            },
            godImage: {
              label: "देवी-देवता की छवि",
              placeholder: "देवी-देवता की छवि अपलोड करें"
            }
          }
        },
      }
    },
  

    // Part 4 - Marathi Translations
marathi: {

  useTemplate: "टेम्पलेट वापरा",
  downloadNow: "आता डाउनलोड करा",
  continueAndDownload: "पुढे जा आणि डाउनलोड करा",
  generating: "कृपया प्रतीक्षा करा..",
  chooseTemplate: "टेम्पलेट निवडा",
  reset: "रीसेट करा",
  selected: "निवडले",
  selectTemplateDesc: "तुमच्या बायोडाटासाठी टेम्पलेट डिझाइन निवडा",
  template: "टेम्पलेट",
  preview: "पूर्वावलोकन",
  fillBiodataFirst:"कोणतेही पूर्वावलोकन उपलब्ध नाही",
  fillBiodataDescription: "तुमचा बायोडेटा पूर्वावलोकन येथे पाहण्यासाठी फॉर्ममध्ये माहिती भरा.",

      title: "तुमचा बायोडाटा तयार करा",
      subtitle: "तुमचा बायोडाटा तयार करण्यासाठी खालील तपशील भरा",
      preview: "बायोडाटा पूर्वावलोकन",
      emptyPreview: {
        title: "तुमचे बायोडाटा पूर्वावलोकन",
        description: "रीअल-टाईम मध्ये तुमचा बायोडाटा पूर्वावलोकन पाहण्यासाठी डावीकडील फॉर्म भरणे सुरू करा."
      },
      addNewField: "नवीन फील्ड जोडा",
      chooseTemplate: "टेम्पलेट निवडा",
      
      form: {
        placeholders: {
          enter: "प्रविष्ट करा",
          select: "निवडा"
        }
      },
      imageUploader: {
        dragDrop: "तुमचा फोटो येथे ड्रॅग आणि ड्रॉप करा किंवा",
        browse: "फाइल्स ब्राउज करा",
        maxSize: "कमाल फाइल आकार:",
        preview: "प्रतिमा पूर्वावलोकन",
        remove: "प्रतिमा काढा",
        fileSizeError: "फाइल आकार यापेक्षा कमी असावा"
      },


      modal: {
        title: "तुमचा प्रोफेशनल बायोडाटा डाउनलोड करा",
        specialOffer: "स्पेशल लाँच ऑफर - आज 50% बचत करा",
        description: "आमच्या प्रोफेशनली डिझाइन केलेल्या बायोडाटा फॉरमॅटसह एक टिकाऊ पहिली छाप तयार करा.",
        sharingInfo: "संभाव्य स्थळं आणि त्यांच्या कुटुंबियांसोबत शेअर करण्यासाठी परफेक्ट.",
        features: {
          premium: {
            title: "प्रीमियम क्वालिटी आउटपुट",
            description: "डिजिटल शेअरिंग आणि प्रिंटिंग दोन्हीसाठी उच्च रेझोल्यूशन PDF आणि PNG"
          },
          secure: {
            title: "सुरक्षित व्यवहार",
            description: "तुमच्या पेमेंट आणि वैयक्तिक माहितीसाठी बँक-ग्रेड सुरक्षा"
          }
        },
        timer: {
          prefix: "मर्यादित वेळेची ऑफर संपत आहे:",
          price: {
            original: "₹49",
            discounted: "FREE",
            suffix: "फक्त"
          }
        },
        downloadButton: {
          default: "प्रीमियम बायोडाटा डाउनलोड करा",
          loading: "फाइल्स तयार होत आहेत..."
        },
        trustBadge: "1,000+ समाधानी ग्राहकांद्वारे विश्वसनीय",
        close: "बंद करा"
      },
      sections: {
        personalDetails: {
          title: "वैयक्तिक माहिती",
          fields: {
            name: {
              label: "नाव",
              placeholder: "तुमचे नाव प्रविष्ट करा"
            },
            dateOfBirth: {
              label: "जन्मतारीख",
              placeholder: "जन्मतारीख निवडा"
            },
            timeOfBirth: {
              label: "जन्मवेळ",
              placeholder: "जन्मवेळ निवडा"
            },
            placeOfBirth: {
              label: "जन्मस्थळ",
              placeholder: "जन्मस्थळ प्रविष्ट करा"
            },
            height: {
              label: "उंची",
              placeholder: "उंची निवडा"
            },
            maritalStatus: {
              label: "वैवाहिक स्थिती",
              placeholder: "वैवाहिक स्थिती निवडा",
              options: {
                single: "अविवाहित",
                divorced: "घटस्फोटित",
                widowed: "विधवा/विधुर",
                separated: "विभक्त"
              }
            },
            complexion: {
              label: "वर्ण",
              placeholder: "वर्ण निवडा",
              options: {
                very_fair: "अति गोरा",
                fair: "गोरा",
                wheatish: "गव्हाळ",
                brown: "सावळा",
                dark: "काळा"
              }
            },
            bloodGroup: {
              label: "रक्तगट",
              placeholder: "रक्तगट निवडा",
              options: {
                a_positive: "A+",
                a_negative: "A-",
                b_positive: "B+",
                b_negative: "B-",
                o_positive: "O+",
                o_negative: "O-",
                ab_positive: "AB+",
                ab_negative: "AB-"
              }
            },
            education: {
              label: "शिक्षण",
              placeholder: "शिक्षणाचा तपशील प्रविष्ट करा"
            },
            collegeName: {
              label: "महाविद्यालयाचे नाव",
              placeholder: "महाविद्यालयाचे नाव प्रविष्ट करा"
            },
            occupation: {
              label: "व्यवसाय",
              placeholder: "व्यवसाय प्रविष्ट करा"
            },
            organizationName: {
              label: "संस्थेचे नाव",
              placeholder: "संस्थेचे नाव प्रविष्ट करा"
            },
            annualIncome: {
              label: "वार्षिक उत्पन्न",
              placeholder: "वार्षिक उत्पन्न निवडा",
              options: {
                "0-5": "0-5 लाख",
                "5-10": "5-10 लाख",
                "10-15": "10-15 लाख",
                "15-20": "15-20 लाख",
                "20-25": "20-25 लाख",
                "25-30": "25-30 लाख",
                "30-35": "30-35 लाख",
                "35-40": "35-40 लाख",
                "40-45": "40-45 लाख",
                "45-50": "45-50 लाख",
                "50+": "50+ लाख"
              }
            },
            religion: {
              label: "धर्म",
              placeholder: "धर्म प्रविष्ट करा"
            },
            motherTongue: {
              label: "मातृभाषा",
              placeholder: "मातृभाषा प्रविष्ट करा"
            },
            caste: {
              label: "जात",
              placeholder: "जात प्रविष्ट करा"
            },

            subCaste: {
              label: "पोटजात",
              placeholder: "पोटजात प्रविष्ट करा"
            },

            
            gotra: {
              label: "गोत्र",
              placeholder: "गोत्र प्रविष्ट करा"
            },
            rashi: {
              label: "राशी",
              placeholder: "राशी निवडा",
              options: {
                mesh: "मेष (Aries)",
                vrishabha: "वृषभ (Taurus)",
                mithuna: "मिथुन (Gemini)",
                karka: "कर्क (Cancer)",
                simha: "सिंह (Leo)",
                kanya: "कन्या (Virgo)",
                tula: "तूळ (Libra)",
                vrischika: "वृश्चिक (Scorpio)", 
                dhanur: "धनु (Sagittarious)",
                makara: "मकर (Capricorn)",
                kumbha: "कुंभ (Aquarius)",
                meena: "मीन (Pisces)"
              }
            },
            nakshatra: {
              label: "नक्षत्र",
              placeholder: "नक्षत्र निवडा",
              options: {
                ashwini: "अश्विनी",
                bharani: "भरणी",
                krittika: "कृत्तिका",
                rohini: "रोहिणी",
                mrigashira: "मृगशीर्ष",
                ardra: "आर्द्रा",
                punarvasu: "पुनर्वसु",
                pushya: "पुष्य",
                ashlesha: "आश्लेषा",
                magha: "मघा",
                purva_phalguni: "पूर्वा फाल्गुनी",
                uttara_phalguni: "उत्तरा फाल्गुनी",
                hasta: "हस्त",
                chitra: "चित्रा",
                swati: "स्वाती",
                vishakha: "विशाखा",
                anuradha: "अनुराधा",
                jyeshtha: "ज्येष्ठा",
                mula: "मूळ",
                purva_ashadha: "पूर्वाषाढा",
                uttara_ashadha: "उत्तराषाढा",
                shravana: "श्रवण",
                dhanishta: "धनिष्ठा",
                shatabhisha: "शततारका",
                purva_bhadrapada: "पूर्वा भाद्रपदा",
                uttara_bhadrapada: "उत्तरा भाद्रपदा",
                revati: "रेवती"
              }
            },
            manglik: {
              label: "मांगलिक",
              placeholder: "मांगलिक स्थिती निवडा",
              options: {
                no: "नाही",
                yes: "होय",
                partial: "अंशतः"
              }
            }
          }
        },
        familyDetails: {
          title: "कौटुंबिक माहिती",
          fields: {
            fatherName: {
              label: "वडिलांचे नाव",
              placeholder: "वडिलांचे नाव प्रविष्ट करा"
            },
            fatherOccupation: {
              label: "वडिलांचा व्यवसाय",
              placeholder: "वडिलांचा व्यवसाय प्रविष्ट करा"
            },
            fatherOrganization: {
              label: "वडिलांची संस्था",
              placeholder: "वडिलांच्या संस्थेचे नाव प्रविष्ट करा"
            },
            motherName: {
              label: "आईचे नाव",
              placeholder: "आईचे नाव प्रविष्ट करा"
            },
            motherOccupation: {
              label: "आईचा व्यवसाय",
              placeholder: "आईचा व्यवसाय प्रविष्ट करा"
            },
            motherOrganization: {
              label: "आईची संस्था",
              placeholder: "आईच्या संस्थेचे नाव प्रविष्ट करा"
            },
            siblings: {
              label: "भावंडांची संख्या",
              placeholder: "भावंडांची संख्या प्रविष्ट करा"
            },
            brothersMarried: {
              label: "विवाहित भाऊ",
              placeholder: "संख्या प्रविष्ट करा"
            },
            brothersUnmarried: {
              label: "अविवाहित भाऊ",
              placeholder: "संख्या प्रविष्ट करा"
            },
            sistersMarried: {
              label: "विवाहित बहिणी",
              placeholder: "संख्या प्रविष्ट करा"
            },
            sistersUnmarried: {
              label: "अविवाहित बहिणी",
              placeholder: "संख्या प्रविष्ट करा"
            },
            familyType: {
              label: "कुटुंबाचा प्रकार",
              placeholder: "कुटुंबाचा प्रकार निवडा",
              options: {
                nuclear: "विभक्त कुटुंब",
                joint: "संयुक्त कुटुंब",
                extended: "विस्तारित कुटुंब"
              }
            },
            familyValues: {
              label: "कौटुंबिक मूल्ये",
              placeholder: "कौटुंबिक मूल्ये निवडा",
              options: {
                traditional: "पारंपारिक",
                moderate: "मध्यम",
                liberal: "उदारमतवादी"
              }
            },
            familyStatus: {
              label: "कौटुंबिक स्थिती",
              placeholder: "कौटुंबिक स्थिती निवडा",
              options: {
                middle_class: "मध्यमवर्गीय",
                upper_middle_class: "उच्च मध्यमवर्गीय",
                rich: "श्रीमंत",
                affluent: "संपन्न"
              }
            },
            nativePlace: {
              label: "मूळ गाव",
              placeholder: "मूळ गाव प्रविष्ट करा"
            }
          }
        },
        contactDetails: {
          title: "संपर्क माहिती",
          fields: {
            address: {
              label: "राहण्याचा पत्ता",
              placeholder: "राहण्याचा पत्ता प्रविष्ट करा"
            },
            landmark: {
              label: "लँडमार्क",
              placeholder: "लँडमार्क प्रविष्ट करा"
            },
            city: {
              label: "शहर",
              placeholder: "शहर प्रविष्ट करा"
            },
            state: {
              label: "राज्य",
              placeholder: "राज्य प्रविष्ट करा"
            },
            pinCode: {
              label: "पिन कोड",
              placeholder: "पिन कोड प्रविष्ट करा"
            },
            phone: {
              label: "फोन नंबर",
              placeholder: "फोन नंबर प्रविष्ट करा"
            },
            alternatePhone: {
              label: "पर्यायी फोन",
              placeholder: "पर्यायी फोन नंबर प्रविष्ट करा"
            },
            email: {
              label: "ईमेल पत्ता",
              placeholder: "ईमेल पत्ता प्रविष्ट करा"
            },
            alternateEmail: {
              label: "पर्यायी ईमेल",
              placeholder: "पर्यायी ईमेल पत्ता प्रविष्ट करा"
            }
          }
        },

        photoUpload: {
          title: "तुमचा फोटो जोडा",
          fields: {
            profilePhoto: {
              label: "प्रोफाइल फोटो",
              placeholder: "प्रोफाइल फोटो अपलोड करा"
            }
          }
        },
        customization: {
          title: "कस्टमायझेशन",
          fields: {
            mantra: {
              label: "मंत्र",
              placeholder: "॥ श्री गणेशाय नमः ॥"
            },
            biodataTitle: {
              label: "बायोडाटा शीर्षक",
              placeholder: "बायोडाटा"
            },
            godImage: {
              label: "देव-देवतांची प्रतिमा",
              placeholder: "देव-देवतांची प्रतिमा अपलोड करा"
            }
          }
        }
      }
    },
    // Part 5 - Gujarati Translations
gujarati: {

  useTemplate: "ટેમ્પ્લેટ વાપરો",
  downloadNow: "હવે ડાઉનલોડ કરો",
  continueAndDownload: "ચાલુ રાખો અને ડાઉનલોડ કરો",
  generating: "મહેરબાની કરીને રાહ જુઓ..",
  chooseTemplate: "ટેમ્પ્લેટ પસંદ કરો",
  reset: "રીસેટ કરો",
  selected: "પસંદ કરેલ",
  selectTemplateDesc: "તમારા બાયોડેટા માટે ટેમ્પ્લેટ ડિઝાઇન પસંદ કરો",
  template: "ટેમ્પ્લેટ",
  preview: "પ્રિવ્યુ",
  fillBiodataFirst:"કોઈ પૂર્વાવલોકન ઉપલબ્ધ નથી",
  fillBiodataDescription: "તમારા બાયોડેટાનો પૂર્વાવલોકન અહીં જોવા માટે ફોર્મમાં માહિતી ભરો.",

      title: "તમારો બાયોડેટા બનાવો",
      subtitle: "તમારો બાયોડેટા જનરેટ કરવા માટે નીચેની વિગતો ભરો",
      preview: "બાયોડેટા પ્રિવ્યુ",
      emptyPreview: {
        title: "તમારો બાયોડેટા પ્રિવ્યુ",
        description: "રીયલ-ટાઇમમાં તમારો બાયોડેટા પ્રિવ્યુ જોવા માટે ડાબી બાજુના ફોર્મમાં ભરવાનું શરૂ કરો."
      },
      addNewField: "નવું ફીલ્ડ ઉમેરો",
      chooseTemplate: "ટેમ્પલેટ પસંદ કરો",
      form: {
        placeholders: {
          enter: "દાખલ કરો",
          select: "પસંદ કરો"
        }
      },
      imageUploader: {
        dragDrop: "તમારો ફોટો અહીં ખેંચો અને છોડો અથવા",
        browse: "ફાઈલો બ્રાઉઝ કરો",
        maxSize: "મહત્તમ ફાઇલ સાઇઝ:",
        preview: "ઇમેજ પ્રિવ્યુ",
        remove: "ઇમેજ દૂર કરો",
        fileSizeError: "ફાઇલની સાઇઝ આનાથી ઓછી હોવી જોઈએ"
      },


      modal: {
        title: "તમારો પ્રોફેશનલ બાયોડેટા ડાઉનલોડ કરો",
        specialOffer: "સ્પેશિયલ લોન્ચ ઓફર - આજે 50% બચાવો",
        description: "અમારા પ્રોફેશનલી ડિઝાઇન કરેલા બાયોડેટા ફોર્મેટ સાથે એક કાયમી પ્રથમ છાપ બનાવો.",
        sharingInfo: "સંભવિત મેચ અને તેમના પરિવારો સાથે શેર કરવા માટે સંપૂર્ણ.",
        features: {
          premium: {
            title: "પ્રીમિયમ ક્વોલિટી આઉટપુટ",
            description: "ડિજિટલ શેરિંગ અને પ્રિન્ટિંગ બંને માટે હાઇ-રેઝોલ્યુશન PDF અને PNG"
          },
          secure: {
            title: "સુરક્ષિત વ્યવહાર",
            description: "તમારી ચુકવણી અને વ્યક્તિગત માહિતી માટે બેંક-ગ્રેડ સુરક્ષા"
          }
        },
        timer: {
          prefix: "મર્યાદિત સમયની ઓફર સમાપ્ત થાય છે:",
          price: {
            original: "₹49",
            discounted: "FREE",
            suffix: "માત્ર"
          }
        },
        downloadButton: {
          default: "પ્રીમિયમ બાયોડેટા ડાઉનલોડ કરો",
          loading: "ફાઇલ્સ જનરેટ થઈ રહી છે..."
        },
        trustBadge: "1,000+ સંતુષ્ટ ગ્રાહકો દ્વારા વિશ્વસનીય",
        close: "બંધ કરો"
      },

      sections: {
        personalDetails: {
          title: "વ્યક્તિગત વિગતો",
          fields: {
            name: {
              label: "નામ",
              placeholder: "તમારું નામ દાખલ કરો"
            },
            dateOfBirth: {
              label: "જન્મ તારીખ",
              placeholder: "જન્મ તારીખ પસંદ કરો"
            },
            timeOfBirth: {
              label: "જન્મ સમય",
              placeholder: "જન્મ સમય પસંદ કરો"
            },
            placeOfBirth: {
              label: "જન્મ સ્થળ",
              placeholder: "જન્મ સ્થળ દાખલ કરો"
            },
            height: {
              label: "ઊંચાઈ",
              placeholder: "ઊંચાઈ પસંદ કરો"
            },
            maritalStatus: {
              label: "વૈવાહિક સ્થિતિ",
              placeholder: "વૈવાહિક સ્થિતિ પસંદ કરો",
              options: {
                single: "અપરણીત",
                divorced: "છૂટાછેડા લીધેલ",
                widowed: "વિધવા/વિધુર",
                separated: "અલગ થયેલ"
              }
            },
            complexion: {
              label: "રંગ",
              placeholder: "રંગ પસંદ કરો",
              options: {
                very_fair: "અતિ ગોરો",
                fair: "ગોરો",
                wheatish: "ઘઉંવર્ણી",
                brown: "બદામી",
                dark: "શ્યામ"
              }
            },
            bloodGroup: {
              label: "રક્તજૂથ",
              placeholder: "રક્તજૂથ પસંદ કરો",
              options: {
                a_positive: "A+",
                a_negative: "A-",
                b_positive: "B+",
                b_negative: "B-",
                o_positive: "O+",
                o_negative: "O-",
                ab_positive: "AB+",
                ab_negative: "AB-"
              }
            },
            education: {
              label: "શિક્ષણ",
              placeholder: "શિક્ષણની વિગતો દાખલ કરો"
            },
            collegeName: {
              label: "કોલેજનું નામ",
              placeholder: "કોલેજનું નામ દાખલ કરો"
            },
            occupation: {
              label: "વ્યવસાય",
              placeholder: "વ્યવસાય દાખલ કરો"
            },
            organizationName: {
              label: "સંસ્થાનું નામ",
              placeholder: "સંસ્થાનું નામ દાખલ કરો"
            },
            annualIncome: {
              label: "વાર્ષિક આવક",
              placeholder: "વાર્ષિક આવક પસંદ કરો",
              options: {
                "0-5": "0-5 લાખ",
                "5-10": "5-10 લાખ",
                "10-15": "10-15 લાખ",
                "15-20": "15-20 લાખ",
                "20-25": "20-25 લાખ",
                "25-30": "25-30 લાખ",
                "30-35": "30-35 લાખ",
                "35-40": "35-40 લાખ",
                "40-45": "40-45 લાખ",
                "45-50": "45-50 લાખ",
                "50+": "50+ લાખ"
              }
            },
            religion: {
              label: "ધર્મ",
              placeholder: "ધર્મ દાખલ કરો"
            },
            motherTongue: {
              label: "માતૃભાષા",
              placeholder: "માતૃભાષા દાખલ કરો"
            },
            caste: {
              label: "જાતિ",
              placeholder: "જાતિ દાખલ કરો"
            },
            subCaste: {
              label: "પેટા જાતિ",
              placeholder: "પેટા જાતિ દાખલ કરો"
            },

            rashi: {
              label: "રાશિ",
              placeholder: "રાશિ પસંદ કરો",
              options: {
                mesh: "મેષ (Aries)",
                vrishabha: "વૃષભ (Taurus)",
                mithuna: "મિથુન (Gemini)",
                karka: "કર્ક (Cancer)",
                simha: "સિંહ (Leo)",
                kanya: "કન્યા (Virgo)",
                tula: "તુલા (Libra)",
                vrischika: "વૃશ્ચિક (Scorpio)",
                dhanur: "ધનુ (Sagittarious)",
                makara: "મકર (Capricorn)",
                kumbha: "કુંભ (Aquarius)",
                meena: "મીન (Pisces)"
              }
            },
            nakshatra: {
              label: "નક્ષત્ર",
              placeholder: "નક્ષત્ર પસંદ કરો",
              options: {
                ashwini: "અશ્વિની",
                bharani: "ભરણી",
                krittika: "કૃત્તિકા",
                rohini: "રોહિણી",
                mrigashira: "મૃગશીર્ષ",
                ardra: "આર્દ્રા",
                punarvasu: "પુનર્વસુ",
                pushya: "પુષ્ય",
                ashlesha: "આશ્લેષા",
                magha: "મઘા",
                purva_phalguni: "પૂર્વા ફાલ્ગુની",
                uttara_phalguni: "ઉત્તરા ફાલ્ગુની",
                hasta: "હસ્ત",
                chitra: "ચિત્રા",
                swati: "સ્વાતિ",
                vishakha: "વિશાખા",
                anuradha: "અનુરાધા",
                jyeshtha: "જ્યેષ્ઠા",
                mula: "મૂલ",
                purva_ashadha: "પૂર્વાષાઢા",
                uttara_ashadha: "ઉત્તરાષાઢા",
                shravana: "શ્રવણ",
                dhanishta: "ધનિષ્ઠા",
                shatabhisha: "શતભિષા",
                purva_bhadrapada: "પૂર્વા ભાદ્રપદ",
                uttara_bhadrapada: "ઉત્તરા ભાદ્રપદ",
                revati: "રેવતી"
              }
            },
            gotra: {
              label: "ગોત્ર",
              placeholder: "ગોત્ર દાખલ કરો"
            },
            manglik: {
              label: "માંગલિક",
              placeholder: "માંગલિક સ્થિતિ પસંદ કરો",
              options: {
                no: "ના",
                yes: "હા",
                partial: "આંશિક"
              }
            }
          }
        },
        familyDetails: {
          title: "કૌટુંબિક વિગતો",
          fields: {
            fatherName: {
              label: "પિતાનું નામ",
              placeholder: "પિતાનું નામ દાખલ કરો"
            },
            fatherOccupation: {
              label: "પિતાનો વ્યવસાય",
              placeholder: "પિતાનો વ્યવસાય દાખલ કરો"
            },
            fatherOrganization: {
              label: "પિતાની સંસ્થા",
              placeholder: "પિતાની સંસ્થાનું નામ દાખલ કરો"
            },
            motherName: {
              label: "માતાનું નામ",
              placeholder: "માતાનું નામ દાખલ કરો"
            },
            motherOccupation: {
              label: "માતાનો વ્યવસાય",
              placeholder: "માતાનો વ્યવસાય દાખલ કરો"
            },
            motherOrganization: {
              label: "માતાની સંસ્થા",
              placeholder: "માતાની સંસ્થાનું નામ દાખલ કરો"
            },
            siblings: {
              label: "ભાઈ-બહેનોની સંખ્યા",
              placeholder: "ભાઈ-બહેનોની સંખ્યા દાખલ કરો"
            },
            brothersMarried: {
              label: "પરણેલા ભાઈઓ",
              placeholder: "સંખ્યા દાખલ કરો"
            },
            brothersUnmarried: {
              label: "અપરણીત ભાઈઓ",
              placeholder: "સંખ્યા દાખલ કરો"
            },
            sistersMarried: {
              label: "પરણેલી બહેનો",
              placeholder: "સંખ્યા દાખલ કરો"
            },
            sistersUnmarried: {
              label: "અપરણીત બહેનો",
              placeholder: "સંખ્યા દાખલ કરો"
            },
            familyType: {
              label: "કુટુંબનો પ્રકાર",
              placeholder: "કુટુંબનો પ્રકાર પસંદ કરો",
              options: {
                nuclear: "વિભક્ત કુટુંબ",
                joint: "સંયુક્ત કુટુંબ",
                extended: "વિસ્તૃત કુટુંબ"
              }
            },
            familyValues: {
              label: "કૌટુંબિક મૂલ્યો",
              placeholder: "કૌટુંબિક મૂલ્યો પસંદ કરો",
              options: {
                traditional: "પરંપરાગત",
                moderate: "મધ્યમ",
                liberal: "ઉદારમતવાદી"
              }
            },
            familyStatus: {
              label: "કૌટુંબિક સ્થિતિ",
              placeholder: "કૌટુંબિક સ્થિતિ પસંદ કરો",
              options: {
                middle_class: "મધ્યમ વર્ગ",
                upper_middle_class: "ઉચ્ચ મધ્યમ વર્ગ",
                rich: "ધનવાન",
                affluent: "સમૃદ્ધ"
              }
            },
            nativePlace: {
              label: "વતન",
              placeholder: "વતન દાખલ કરો"
            }
          }
        },
        contactDetails: {
          title: "સંપર્ક વિગતો",
          fields: {
            address: {
              label: "રહેઠાણનું સરનામું",
              placeholder: "રહેઠાણનું સરનામું દાખલ કરો"
            },
            landmark: {
              label: "લેન્ડમાર્ક",
              placeholder: "લેન્ડમાર્ક દાખલ કરો"
            },
            city: {
              label: "શહેર",
              placeholder: "શહેર દાખલ કરો"
            },
            state: {
              label: "રાજ્ય",
              placeholder: "રાજ્ય દાખલ કરો"
            },
            pinCode: {
              label: "પિન કોડ",
              placeholder: "પિન કોડ દાખલ કરો"
            },
            phone: {
              label: "ફોન નંબર",
              placeholder: "ફોન નંબર દાખલ કરો"
            },
            alternatePhone: {
              label: "વૈકલ્પિક ફોન",
              placeholder: "વૈકલ્પિક ફોન નંબર દાખલ કરો"
            },
            email: {
              label: "ઈમેલ સરનામું",
              placeholder: "ઈમેલ સરનામું દાખલ કરો"
            },
            alternateEmail: {
              label: "વૈકલ્પિક ઈમેલ",
              placeholder: "વૈકલ્પિક ઈમેલ સરનામું દાખલ કરો"
            }
          }
        },
        photoUpload: {
          title: "તમારો ફોટો ઉમેરો",
          fields: {
            profilePhoto: {
              label: "પ્રોફાઇલ ફોટો",
              placeholder: "પ્રોફાઇલ ફોટો અપલોડ કરો"
            }
          }
        },
        customization: {
          title: "કસ્ટમાઇઝેશન",
          fields: {
            mantra: {
              label: "મંત્ર",
              placeholder: "॥ શ્રી ગણેશાય નમઃ ॥"
            },
            biodataTitle: {
              label: "બાયોડેટા શીર્ષક",
              placeholder: "બાયોડેટા"
            },
            godImage: {
              label: "દેવ-દેવીની છબી",
              placeholder: "દેવ-દેવીની છબી અપલોડ કરો"
            }
          }
        }
      }
    },
    
   
    // Part 6 - Kannada Translations
kannada: {

  useTemplate: "ಟೆಂಪ್ಲೇಟ್ ಬಳಸಿ",
  downloadNow: "ಈಗ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
  continueAndDownload: "ಮುಂದುವರಿಸಿ ಮತ್ತು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
  generating: "ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ..",
  chooseTemplate: "ಟೆಂಪ್ಲೇಟ್ ಆಯ್ಕೆಮಾಡಿ",
  reset: "ಮರುಹೊಂದಿಸಿ",
  selected: "પસંદ કરેલ",
  selectTemplateDesc: "ನಿಮ್ಮ ಬಯೋಡಾಟಾಗಾಗಿ ಟೆಂಪ್ಲೇಟ್ ವಿನ್ಯಾಸವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  template: "ಟೆಂಪ್ಲೇಟ್",
  preview: "ಪೂರ್ವವೀಕ್ಷಣೆ",
  fillBiodataFirst:"ಯಾವುದೇ ಪೂರ್ವವೀಕ್ಷಣೆ ಲಭ್ಯವಿಲ್ಲ",
  fillBiodataDescription: "ನಿಮ್ಮ ಬಯೋಡೇಟಾ ಪೂರ್ವವೀಕ್ಷಣೆಯನ್ನು ಇಲ್ಲಿ ನೋಡಲು ಫಾರ್ಮ್‌ನಲ್ಲಿ ಮಾಹಿತಿಯನ್ನು ಭರ್ತಿ ಮಾಡಿ.",
  
      title: "ನಿಮ್ಮ ಬಯೋಡಾಟಾ ರಚಿಸಿ",
      subtitle: "ನಿಮ್ಮ ಬಯೋಡಾಟಾ ಜನರೇಟ್ ಮಾಡಲು ಕೆಳಗಿನ ವಿವರಗಳನ್ನು ಭರ್ತಿ ಮಾಡಿ",
      preview: "ಬಯೋಡಾಟಾ ಪೂರ್ವವೀಕ್ಷಣೆ",
      emptyPreview: {
        title: "ನಿಮ್ಮ ಬಯೋಡಾಟಾ ಪೂರ್ವವೀಕ್ಷಣೆ",
        description: "ರಿಯಲ್-ಟೈಮ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಬಯೋಡಾಟಾ ಪೂರ್ವವೀಕ್ಷಣೆಯನ್ನು ನೋಡಲು ಎಡಭಾಗದಲ್ಲಿರುವ ಫಾರ್ಮ್ ಅನ್ನು ಭರ್ತಿ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿ."
      },
      addNewField: "ಹೊಸ ಕ್ಷೇತ್ರವನ್ನು ಸೇರಿಸಿ",
      chooseTemplate: "ಟೆಂಪ್ಲೇಟ್ ಆಯ್ಕೆಮಾಡಿ",
      form: {
        placeholders: {
          enter: "ನಮೂದಿಸಿ",
          select: "ಆಯ್ಕೆಮಾಡಿ"
        }
      },
      imageUploader: {
        dragDrop: "ನಿಮ್ಮ ಫೋಟೋವನ್ನು ಇಲ್ಲಿ ಎಳೆದು ಬಿಡಿ ಅಥವಾ",
        browse: "ಫೈಲ್‌ಗಳನ್ನು ಬ್ರೌಸ್ ಮಾಡಿ",
        maxSize: "ಗರಿಷ್ಠ ಫೈಲ್ ಗಾತ್ರ:",
        preview: "ಚಿತ್ರ ಪೂರ್ವವೀಕ್ಷಣೆ",
        remove: "ಚಿತ್ರವನ್ನು ತೆಗೆದುಹಾಕಿ",
        fileSizeError: "ಫೈಲ್ ಗಾತ್ರವು ಇದಕ್ಕಿಂತ ಕಡಿಮೆ ಇರಬೇಕು"
      },

      modal: {
        title: "ನಿಮ್ಮ ಪ್ರೊಫೆಷನಲ್ ಬಯೋಡಾಟಾ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
        specialOffer: "ವಿಶೇಷ ಲಾಂಚ್ ಆಫರ್ - ಇಂದು 50% ಉಳಿಸಿ",
        description: "ನಮ್ಮ ಪ್ರೊಫೆಷನಲ್ ವಿನ್ಯಾಸದ ಬಯೋಡಾಟಾ ಫಾರ್ಮ್ಯಾಟ್‌ನೊಂದಿಗೆ ಶಾಶ್ವತ ಮೊದಲ ಒತ್ತು ಮೂಡಿಸಿ.",
        sharingInfo: "ಸಂಭಾವ್ಯ ಜೋಡಿಗಳು ಮತ್ತು ಅವರ ಕುಟುಂಬಗಳೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲು ಸೂಕ್ತ.",
        features: {
          premium: {
            title: "ಪ್ರೀಮಿಯಂ ಗುಣಮಟ್ಟದ ಔಟ್‌ಪುಟ್",
            description: "ಡಿಜಿಟಲ್ ಹಂಚಿಕೆ ಮತ್ತು ಮುದ್ರಣ ಎರಡಕ್ಕೂ ಹೈ-ರೆಸಲ್ಯೂಷನ್ PDF ಮತ್ತು PNG"
          },
          secure: {
            title: "ಸುರಕ್ಷಿತ ವಹಿವಾಟು",
            description: "ನಿಮ್ಮ ಪಾವತಿ ಮತ್ತು ವೈಯಕ್ತಿಕ ಮಾಹಿತಿಗಾಗಿ ಬ್ಯಾಂಕ್-ಗ್ರೇಡ್ ಭದ್ರತೆ"
          }
        },
        timer: {
          prefix: "ಸೀಮಿತ ಸಮಯದ ಆಫರ್ ಮುಗಿಯುತ್ತಿದೆ:",
          price: {
            original: "₹49",
            discounted: "FREE",
            suffix: "ಮಾತ್ರ"
          }
        },
        downloadButton: {
          default: "ಪ್ರೀಮಿಯಂ ಬಯೋಡಾಟಾ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
          loading: "ಫೈಲ್‌ಗಳನ್ನು ರಚಿಸಲಾಗುತ್ತಿದೆ..."
        },
        trustBadge: "1,000+ ಸಂತೃಪ್ತ ಗ್ರಾಹಕರಿಂದ ವಿಶ್ವಾಸಾರ್ಹ",
        close: "ಮುಚ್ಚಿರಿ"
      },

      sections: {
        personalDetails: {
          title: "ವೈಯಕ್ತಿಕ ವಿವರಗಳು",
          fields: {
            name: {
              label: "ಹೆಸರು",
              placeholder: "ನಿಮ್ಮ ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
            },
            dateOfBirth: {
              label: "ಜನ್ಮ ದಿನಾಂಕ",
              placeholder: "ಜನ್ಮ ದಿನಾಂಕವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
            },
            timeOfBirth: {
              label: "ಜನ್ಮ ಸಮಯ",
              placeholder: "ಜನ್ಮ ಸಮಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
            },
            placeOfBirth: {
              label: "ಜನ್ಮ ಸ್ಥಳ",
              placeholder: "ಜನ್ಮ ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
            },
            height: {
              label: "ಎತ್ತರ",
              placeholder: "ಎತ್ತರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
            },
            maritalStatus: {
              label: "ವೈವಾಹಿಕ ಸ್ಥಿತಿ",
              placeholder: "ವೈವಾಹಿಕ ಸ್ಥಿತಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
              options: {
                single: "ಅವಿವಾಹಿತ",
                divorced: "ವಿಚ್ಛೇದಿತ",
                widowed: "ವಿಧವೆ/ವಿಧುರ",
                separated: "ಪ್ರತ್ಯೇಕಗೊಂಡ"
              }
            },
            complexion: {
              label: "ಬಣ್ಣ",
              placeholder: "ಬಣ್ಣವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
              options: {
                very_fair: "ಅತ್ಯಂತ ಬಿಳಿ",
                fair: "ಬಿಳಿ",
                wheatish: "ಗೋಧಿ ಬಣ್ಣ",
                brown: "ಕಂದು",
                dark: "ಕಪ್ಪು"
              }
            },
            bloodGroup: {
              label: "ರಕ್ತ ಗುಂಪು",
              placeholder: "ರಕ್ತ ಗುಂಪನ್ನು ಆಯ್ಕೆಮಾಡಿ",
              options: {
                a_positive: "A+",
                a_negative: "A-",
                b_positive: "B+",
                b_negative: "B-",
                o_positive: "O+",
                o_negative: "O-",
                ab_positive: "AB+",
                ab_negative: "AB-"
              }
            },
            education: {
              label: "ವಿದ್ಯಾಭ್ಯಾಸ",
              placeholder: "ವಿದ್ಯಾಭ್ಯಾಸದ ವಿವರಗಳನ್ನು ನಮೂದಿಸಿ"
            },
            collegeName: {
              label: "ಕಾಲೇಜಿನ ಹೆಸರು",
              placeholder: "ಕಾಲೇಜಿನ ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
            },
            occupation: {
              label: "ಉದ್ಯೋಗ",
              placeholder: "ಉದ್ಯೋಗವನ್ನು ನಮೂದಿಸಿ"
            },
            organizationName: {
              label: "ಸಂಸ್ಥೆಯ ಹೆಸರು",
              placeholder: "ಸಂಸ್ಥೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
            },
            annualIncome: {
              label: "ವಾರ್ಷಿಕ ಆದಾಯ",
              placeholder: "ವಾರ್ಷಿಕ ಆದಾಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
              options: {
                "0-5": "0-5 ಲಕ್ಷ",
                "5-10": "5-10 ಲಕ್ಷ",
                "10-15": "10-15 ಲಕ್ಷ",
                "15-20": "15-20 ಲಕ್ಷ",
                "20-25": "20-25 ಲಕ್ಷ",
                "25-30": "25-30 ಲಕ್ಷ",
                "30-35": "30-35 ಲಕ್ಷ",
                "35-40": "35-40 ಲಕ್ಷ",
                "40-45": "40-45 ಲಕ್ಷ",
                "45-50": "45-50 ಲಕ್ಷ",
                "50+": "50+ ಲಕ್ಷ"
              }
            },
            religion: {
              label: "ಧರ್ಮ",
              placeholder: "ಧರ್ಮವನ್ನು ನಮೂದಿಸಿ"
            },
            motherTongue: {
              label: "ಮಾತೃಭಾಷೆ",
              placeholder: "ಮಾತೃಭಾಷೆಯನ್ನು ನಮೂದಿಸಿ"
            },
            caste: {
              label: "ಜಾತಿ",
              placeholder: "ಜಾತಿಯನ್ನು ನಮೂದಿಸಿ"
            },
            subCaste: {
              label: "ಉಪಜಾತಿ",
              placeholder: "ಉಪಜಾತಿಯನ್ನು ನಮೂದಿಸಿ"
            },
            rashi: {
              label: "ರಾಶಿ",
              placeholder: "ರಾಶಿ ಆಯ್ಕೆಮಾಡಿ",
              options: {
                mesh: "ಮೇಷ (Aries)",
                vrishabha: "ವೃಷಭ (Taurus)",
                mithuna: "ಮಿಥುನ (Gemini)",
                karka: "ಕರ್ಕ (Cancer)",
                simha: "ಸಿಂಹ (Leo)",
                kanya: "ಕನ್ಯಾ (Virgo)",
                tula: "ತುಲಾ (Libra)",
                vrischika: "ವೃಶ್ಚಿಕ (Scorpio)",
                dhanur: "ಧನು (Sagittarious)",
                makara: "ಮಕರ (Capricorn)",
                kumbha: "ಕುಂಭ (Aquarius)",
                meena: "ಮೀನ (Pisces)"
              }
            },
            nakshatra: {
              label: "ನಕ್ಷತ್ರ",
              placeholder: "ನಕ್ಷತ್ರ ಆಯ್ಕೆಮಾಡಿ",
              options: {
                ashwini: "ಅಶ್ವಿನಿ",
                bharani: "ಭರಣಿ",
                krittika: "ಕೃತ್ತಿಕಾ",
                rohini: "ರೋಹಿಣಿ",
                mrigashira: "ಮೃಗಶಿರ",
                ardra: "ಆರ್ದ್ರಾ",
                punarvasu: "ಪುನರ್ವಸು",
                pushya: "ಪುಷ್ಯ",
                ashlesha: "ಆಶ್ಲೇಷ",
                magha: "ಮಘ",
                purva_phalguni: "ಪೂರ್ವ ಫಲ್ಗುಣಿ",
                uttara_phalguni: "ಉತ್ತರ ಫಲ್ಗುಣಿ",
                hasta: "ಹಸ್ತ",
                chitra: "ಚಿತ್ರ",
                swati: "ಸ್ವಾತಿ",
                vishakha: "ವಿಶಾಖ",
                anuradha: "ಅನುರಾಧ",
                jyeshtha: "ಜ್ಯೇಷ್ಠ",
                mula: "ಮೂಲ",
                purva_ashadha: "ಪೂರ್ವಾಷಾಢ",
                uttara_ashadha: "ಉತ್ತರಾಷಾಢ",
                shravana: "ಶ್ರವಣ",
                dhanishta: "ಧನಿಷ್ಠ",
                shatabhisha: "ಶತಭಿಷ",
                purva_bhadrapada: "ಪೂರ್ವ ಭಾದ್ರಪದ",
                uttara_bhadrapada: "ಉತ್ತರ ಭಾದ್ರಪದ",
                revati: "ರೇವತಿ"
              }
            },
            gotra: {
              label: "ಗೋತ್ರ",
              placeholder: "ಗೋತ್ರವನ್ನು ನಮೂದಿಸಿ"
            },
            manglik: {
              label: "ಮಂಗಳಿಕ",
              placeholder: "ಮಂಗಳಿಕ ಸ್ಥಿತಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
              options: {
                no: "ಇಲ್ಲ",
                yes: "ಹೌದು",
                partial: "ಭಾಗಶಃ"
              }
            }
          }
        },
        familyDetails: {
          title: "ಕುಟುಂಬದ ವಿವರಗಳು",
          fields: {
            fatherName: {
              label: "ತಂದೆಯ ಹೆಸರು",
              placeholder: "ತಂದೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
            },
            fatherOccupation: {
              label: "ತಂದೆಯ ಉದ್ಯೋಗ",
              placeholder: "ತಂದೆಯ ಉದ್ಯೋಗವನ್ನು ನಮೂದಿಸಿ"
            },
            fatherOrganization: {
              label: "ತಂದೆಯ ಸಂಸ್ಥೆ",
              placeholder: "ತಂದೆಯ ಸಂಸ್ಥೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
            },
            motherName: {
              label: "ತಾಯಿಯ ಹೆಸರು",
              placeholder: "ತಾಯಿಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
            },
            motherOccupation: {
              label: "ತಾಯಿಯ ಉದ್ಯೋಗ",
              placeholder: "ತಾಯಿಯ ಉದ್ಯೋಗವನ್ನು ನಮೂದಿಸಿ"
            },
            motherOrganization: {
              label: "ತಾಯಿಯ ಸಂಸ್ಥೆ",
              placeholder: "ತಾಯಿಯ ಸಂಸ್ಥೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
            },
            siblings: {
              label: "ಸಹೋದರ-ಸಹೋದರಿಯರ ಸಂಖ್ಯೆ",
              placeholder: "ಸಹೋದರ-ಸಹೋದರಿಯರ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"
            },
            brothersMarried: {
              label: "ಮದುವೆಯಾದ ಸಹೋದರರು",
              placeholder: "ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"
            },
            brothersUnmarried: {
                  label: "ಅವಿವಾಹಿತ ಸಹೋದರರು",
                  placeholder: "ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"
                },
                sistersMarried: {
                  label: "ಮದುವೆಯಾದ ಸಹೋದರಿಯರು",
                  placeholder: "ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"
                },
                sistersUnmarried: {
                  label: "ಅವಿವಾಹಿತ ಸಹೋದರಿಯರು",
                  placeholder: "ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"
                },
                familyType: {
                  label: "ಕುಟುಂಬದ ಪ್ರಕಾರ",
                  placeholder: "ಕುಟುಂಬದ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
                  options: {
                    nuclear: "ವಿಭಕ್ತ ಕುಟುಂಬ",
                    joint: "ಸಂಯುಕ್ತ ಕುಟುಂಬ",
                    extended: "ವಿಸ್ತೃತ ಕುಟುಂಬ"
                  }
                },
                familyValues: {
                  label: "ಕುಟುಂಬದ ಮೌಲ್ಯಗಳು",
                  placeholder: "ಕುಟುಂಬದ ಮೌಲ್ಯಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
                  options: {
                    traditional: "ಸಾಂಪ್ರದಾಯಿಕ",
                    moderate: "ಮಧ್ಯಮ",
                    liberal: "ಉದಾರವಾದಿ"
                  }
                },
                familyStatus: {
                  label: "ಕುಟುಂಬದ ಸ್ಥಿತಿ",
                  placeholder: "ಕುಟುಂಬದ ಸ್ಥಿತಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
                  options: {
                    middle_class: "ಮಧ್ಯಮ ವರ್ಗ",
                    upper_middle_class: "ಉನ್ನತ ಮಧ್ಯಮ ವರ್ಗ",
                    rich: "ಶ್ರೀಮಂತ",
                    affluent: "ಸಂಪನ್ನ"
                  }
                },
                nativePlace: {
                  label: "ಸ್ವಸ್ಥಳ",
                  placeholder: "ಸ್ವಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
                }
              }
            },
            contactDetails: {
              title: "ಸಂಪರ್ಕ ವಿವರಗಳು",
              fields: {
                address: {
                  label: "ವಾಸಸ್ಥಳದ ವಿಳಾಸ",
                  placeholder: "ವಾಸಸ್ಥಳದ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ"
                },
                landmark: {
                  label: "ಲ್ಯಾಂಡ್‌ಮಾರ್ಕ್",
                  placeholder: "ಲ್ಯಾಂಡ್‌ಮಾರ್ಕ್ ನಮೂದಿಸಿ"
                },
                city: {
                  label: "ನಗರ",
                  placeholder: "ನಗರವನ್ನು ನಮೂದಿಸಿ"
                },
                state: {
                  label: "ರಾಜ್ಯ",
                  placeholder: "ರಾಜ್ಯವನ್ನು ನಮೂದಿಸಿ"
                },
                pinCode: {
                  label: "ಪಿನ್ ಕೋಡ್",
                  placeholder: "ಪಿನ್ ಕೋಡ್ ನಮೂದಿಸಿ"
                },
                phone: {
                  label: "ಫೋನ್ ಸಂಖ್ಯೆ",
                  placeholder: "ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"
                },
                alternatePhone: {
                  label: "ಪರ್ಯಾಯ ಫೋನ್",
                  placeholder: "ಪರ್ಯಾಯ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"
                },
                email: {
                  label: "ಇಮೇಲ್ ವಿಳಾಸ",
                  placeholder: "ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ"
                },
                alternateEmail: {
                  label: "ಪರ್ಯಾಯ ಇಮೇಲ್",
                  placeholder: "ಪರ್ಯಾಯ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ"
                }
              }
            },
            photoUpload: {
              title: "ನಿಮ್ಮ ಫೋಟೋ ಸೇರಿಸಿ",
              fields: {
                profilePhoto: {
                  label: "ಪ್ರೊಫೈಲ್ ಫೋಟೋ",
                  placeholder: "ಪ್ರೊಫೈಲ್ ಫೋಟೋ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ"
                }
              }
            },
            customization: {
              title: "ಕಸ್ಟಮೈಸೇಶನ್",
              fields: {
                mantra: {
                  label: "ಮಂತ್ರ",
                  placeholder: "॥ ಶ್ರೀ ಗಣೇಶಾಯ ನಮಃ ॥"
                },
                biodataTitle: {
                  label: "ಬಯೋಡಾಟಾ ಶೀರ್ಷಿಕೆ",
                   placeholder: "ಬಯೋಡಾಟಾ"
                },
                godImage: {
                  label: "ದೇವರ ಚಿತ್ರ",
                  placeholder: "ದೇವರ ಚಿತ್ರವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ"
                }
              }
          },}
        },
      
      };

      // Helper functions
      export const getTranslation = (language, key) => {
        const keys = key.split('.');
        let value = translations[language];
        
        for (const k of keys) {
          value = value?.[k];
          if (!value) break;
        }
        
        return value || key;
      };
      
      // Date and number formatting
      export const dateFormats = {
        english: 'en-US',
        hindi: 'hi-IN',
        marathi: 'mr-IN',
        gujarati: 'gu-IN',
        kannada: 'kn-IN'
      };
      
      export const numberFormats = {
        english: 'en-IN',
        hindi: 'hi-IN',
        marathi: 'mr-IN',
        gujarati: 'gu-IN',
        kannada: 'kn-IN'
      };
      
      // Custom hook for translations
      export const useTranslation = (language) => {
        return {
          t: (key) => getTranslation(language, key),
          dateFormat: dateFormats[language],
          numberFormat: numberFormats[language]
        };
      };


      const monthTranslations = {
        english: {
          January: "January",
          February: "February", 
          March: "March",
          April: "April",
          May: "May",
          June: "June",
          July: "July",
          August: "August",
          September: "September",
          October: "October",
          November: "November",
          December: "December"
        },
        hindi: {
          January: "जनवरी",
          February: "फरवरी",
          March: "मार्च",
          April: "अप्रैल", 
          May: "मई",
          June: "जून",
          July: "जुलाई",
          August: "अगस्त",
          September: "सितंबर",
          October: "अक्टूबर",
          November: "नवंबर",
          December: "दिसंबर"
        },
        marathi: {
          January: "जानेवारी",
          February: "फेब्रुवारी",
          March: "मार्च",
          April: "एप्रिल",
          May: "मे",
          June: "जून",
          July: "जुलै",
          August: "ऑगस्ट",
          September: "सप्टेंबर",
          October: "ऑक्टोबर",
          November: "नोव्हेंबर",
          December: "डिसेंबर"
        },
        gujarati: {
          January: "જાન્યુઆરી",
          February: "ફેબ્રુઆરી",
          March: "માર્ચ",
          April: "એપ્રિલ",
          May: "મે",
          June: "જૂન",
          July: "જુલાઈ",
          August: "ઓગસ્ટ",
          September: "સપ્ટેમ્બર",
          October: "ઓક્ટોબર",
          November: "નવેમ્બર",
          December: "ડિસેમ્બર"
        },
        kannada: {
          January: "ಜನವರಿ",
          February: "ಫೆಬ್ರವರಿ",
          March: "ಮಾರ್ಚ್",
          April: "ಏಪ್ರಿಲ್",
          May: "ಮೇ",
          June: "ಜೂನ್",
          July: "ಜುಲೈ",
          August: "ಆಗಸ್ಟ್",
          September: "ಸೆಪ್ಟೆಂಬರ್",
          October: "ಅಕ್ಟೋಬರ್",
          November: "ನವೆಂಬರ್",
          December: "ಡಿಸೆಂಬರ್"
        }
      };

      export { monthTranslations };