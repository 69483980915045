import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="bg-rose-50 min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
          Terms & Conditions
        </h1>

        <div className="bg-white rounded-lg shadow-sm p-8 space-y-6">
          <section className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800">
              Welcome to Matrimonial Biodata
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Thank you for choosing Matrimonial Biodata for creating your marriage biodata. By using our website, you agree to these terms and conditions. Our service is designed to help you create professional marriage biodatas easily and efficiently.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mt-6">
              Our Service
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Matrimonial Biodata provides a platform for creating, customizing, and downloading marriage biodatas. We offer various templates and customization options to help you present yourself effectively. While our basic service is free, we may introduce premium features in the future with clear pricing information.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mt-6">
              Your Information & Privacy
            </h2>
            <p className="text-gray-600 leading-relaxed">
              When you create a biodata using our service, all processing happens directly in your browser. We do not store your personal information on our servers. The information you enter remains private and secure. You are responsible for the accuracy and appropriateness of the information you include in your biodata.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mt-6">
              Usage Guidelines
            </h2>
            <p className="text-gray-600 leading-relaxed">
              While using Matrimonial Biodata, you agree to:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>Provide accurate and truthful information in your biodata</li>
              <li>Not misuse our templates or service for any unauthorized purpose</li>
              <li>Respect the privacy and rights of others</li>
              <li>Not attempt to modify, reverse-engineer, or compromise our website</li>
            </ul>

            <h2 className="text-xl font-semibold text-gray-800 mt-6">
              Content Rights
            </h2>
            <p className="text-gray-600 leading-relaxed">
              All templates, designs, and features provided by Matrimonial Biodata are our intellectual property. While you own the content you create using our service, the underlying templates and system remain our property. You may not copy, modify, or redistribute our templates or website features.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mt-6">
              Disclaimer
            </h2>
            <p className="text-gray-600 leading-relaxed">
              While we strive to provide a reliable service, Matrimonial Biodata is provided "as is" without warranties. We are not responsible for any outcomes resulting from the use of biodatas created through our service. Users are advised to review their biodatas carefully before sharing them.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mt-6">
              Updates to Terms
            </h2>
            <p className="text-gray-600 leading-relaxed">
              We may update these terms as our service evolves. Significant changes will be announced on our website. Your continued use of Matrimonial Biodata after changes indicates acceptance of the updated terms.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mt-6">
              Contact Information
            </h2>
            <p className="text-gray-600 leading-relaxed">
              If you have questions about these terms or our service, please contact us at support@matrimonialbiodata.in. We're here to help make your biodata creation experience smooth and successful.
            </p>

            <p className="text-sm text-gray-500 mt-8 text-center">
              Last updated: March 20, 2023
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;