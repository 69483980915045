import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FAQs = () => {
  const faqs = [
    {
      category: "Basic Questions",
      questions: [
        {
          question: "What is Matrimonial Biodata?",
          answer: "Matrimonial Biodata is a free online tool that helps you create professional marriage biodata. Simply fill in your details, and we'll format them into a beautiful biodata - no design skills needed!"
        },
        {
          question: "Do I need to create an account?",
          answer: "No, you don't need to create an account. Just visit Matrimonial Biodata and start creating your marriage biodata right away."
        },
        {
          question: "What should I include in my biodata?",
          answer: "Include your basic details (name, age, location), education and job information, family details, contact details, and what you're looking for in a partner. Keep it honest and clear."
        }
      ]
    },
    {
      category: "Design & Features",
      questions: [
        {
          question: "Can I change how my biodata looks?",
          answer: "Yes! You can choose different designs, colors, and layouts. You can also add or remove fields to make your biodata exactly how you want it."
        },
        {
          question: "Can I add my photo?",
          answer: "Yes, you can add one profile photo to your biodata. A good photo helps create a better first impression."
        },
        {
          question: "How many biodatas can I make?",
          answer: "You can create unlimited biodatas using our free and professional templates. There's no limit - make as many as you need!"
        }
      ]
    },
    {
      category: "Privacy & Help",
      questions: [
        {
          question: "Is my information safe?",
          answer: "Yes, your information is completely safe. We don't store any of your personal details - everything is processed right on your device."
        },
        {
          question: "Can I edit my biodata later?",
          answer: "Since we don't save your information, you'll need to create a new biodata if you want to make changes. We recommend checking all details carefully before downloading."
        },
        {
          question: "How can I get help?",
          answer: "If you need any help, email us at support@matrimonialbiodata.in. We're here to make your biodata creation process smooth and easy!"
        }
      ]
    }
  ];


  const [openCategories, setOpenCategories] = useState(new Set([0])); // First category open by default

  const toggleCategory = (index) => {
    setOpenCategories(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  return (
    <div className="bg-rose-50 min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-12">
          Common Questions About Matrimonial Biodata
        </h1>

        <div className="space-y-6">
          {faqs.map((category, categoryIndex) => (
            <div key={categoryIndex} className="bg-white rounded-lg shadow-sm">
              <button
                onClick={() => toggleCategory(categoryIndex)}
                className="w-full px-6 py-4 flex justify-between items-center text-left focus:outline-none"
              >
                <h2 className="text-xl font-semibold text-gray-800">
                  {category.category}
                </h2>
                {openCategories.has(categoryIndex) ? (
                  <FaChevronUp className="text-gray-500" />
                ) : (
                  <FaChevronDown className="text-gray-500" />
                )}
              </button>

              {openCategories.has(categoryIndex) && (
                <div className="px-6 pb-6 space-y-6">
                  {category.questions.map((faq, faqIndex) => (
                    <div key={faqIndex} className="space-y-2">
                      <h3 className="font-medium text-gray-900">
                        {faq.question}
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                        {faq.answer}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;