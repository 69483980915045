import React from 'react';
import { FaEnvelope, FaClock, FaQuestionCircle, FaExclamationCircle } from 'react-icons/fa';

const ContactUs = () => {
  const contactSections = [
    {
      icon: <FaEnvelope />,
      title: "General Support",
      description: "For general inquiries, template issues, or technical support",
      email: "support@matrimonialbiodata.in",
      responseTime: "We aim to respond within 24 hours"
    },
    {
      icon: <FaQuestionCircle />,
      title: "Help & Guidance",
      description: "Questions about how to use our service or create your biodata",
      bulletPoints: [
        "Check our comprehensive FAQ section",
        "View our detailed How It Works guide",
        "Read our user documentation"
      ]
    },
    {
      icon: <FaExclamationCircle />,
      title: "Important Information",
      description: "Before contacting us, please note:",
      bulletPoints: [
        "We provide 24/7 email support",
        "Include your order ID (if applicable) in all communications",
        "Attach relevant screenshots for technical issues",
        "Check our FAQs for immediate answers to common questions"
      ]
    },
    {
      icon: <FaClock />,
      title: "Support Hours",
      description: "When you can expect to hear from us",
      bulletPoints: [
        "Monday to Saturday: 10:00 AM - 7:00 PM (IST)",
        "Response within 24 hours",
        "Weekend responses may take longer",
        "Holiday schedule posted on our social media"
      ]
    }
  ];

  return (
    <div className="bg-rose-50 min-h-screen">
      <div className="max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Contact Us
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            We're here to help you create the perfect marriage biodata. Get in touch with us for support and assistance.
          </p>
        </div>

        {/* Primary Contact Section */}
        <div className="bg-white rounded-xl shadow-sm p-8 mb-8 text-center">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Email Us
          </h2>
          <a 
            href="mailto:support@matrimonialbiodata.in" 
            className="text-xl text-rose-500 hover:text-rose-600 font-medium"
          >
            support@matrimonialbiodata.in
          </a>
          <p className="text-gray-600 mt-2">
            This is the fastest way to reach us
          </p>
        </div>

        {/* Contact Information Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {contactSections.map((section, index) => (
            <div key={index} className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow duration-300">
              <div className="flex items-start">
                <div className="w-12 h-12 rounded-lg bg-rose-100 flex items-center justify-center flex-shrink-0">
                  {React.cloneElement(section.icon, { className: "w-6 h-6 text-rose-500" })}
                </div>
                <div className="ml-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">
                    {section.title}
                  </h3>
                  <p className="text-gray-600 mb-3">
                    {section.description}
                  </p>
                  {section.email && (
                    <a 
                      href={`mailto:${section.email}`}
                      className="text-rose-500 hover:text-rose-600 font-medium block mb-2"
                    >
                      {section.email}
                    </a>
                  )}
                  {section.responseTime && (
                    <p className="text-sm text-gray-500">
                      {section.responseTime}
                    </p>
                  )}
                  {section.bulletPoints && (
                    <ul className="space-y-2 mt-3">
                      {section.bulletPoints.map((point, idx) => (
                        <li key={idx} className="flex items-start text-gray-600 text-sm">
                          <span className="w-2 h-2 bg-rose-400 rounded-full mt-1.5 mr-3 flex-shrink-0"></span>
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Quick Links Section */}
        <div className="bg-white rounded-xl shadow-sm p-8 mt-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
            Quick Links
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
            <a href="/faqs" className="text-rose-500 hover:text-rose-600 font-medium">
              Frequently Asked Questions
            </a>
            <a href="/how-it-works" className="text-rose-500 hover:text-rose-600 font-medium">
              How It Works
            </a>
            <a href="/terms-conditions" className="text-rose-500 hover:text-rose-600 font-medium">
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;