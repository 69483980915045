// hooks/useBiodataStorage.js
import { useState, useEffect } from 'react';

export const useBiodataStorage = () => {
  const [storedBiodata, setStoredBiodata] = useState(null);

  useEffect(() => {
    const getBiodataFromStorage = () => {
      try {
        const stored = localStorage.getItem('biodataStorage');
        if (stored) {
          setStoredBiodata(JSON.parse(stored));
        }
      } catch (error) {
        console.error('Error retrieving biodata from storage:', error);
      }
    };

    getBiodataFromStorage();
  }, []);

  const clearBiodata = () => {
    try {
      localStorage.removeItem('biodataStorage');
      setStoredBiodata(null);
    } catch (error) {
      console.error('Error clearing biodata from storage:', error);
    }
  };

  return { storedBiodata, clearBiodata };
};