// src/components/BioDataMaker/BiodataDownloadModal.jsx
import React, { useState, useEffect } from 'react';
import { getTranslation } from './translations';

const BiodataDownloadModal = ({ 
  isOpen, 
  onClose, 
  onDownload,
  selectedLanguage 
}) => {
  const [timeLeft, setTimeLeft] = useState(899);
  const [isDownloading, setIsDownloading] = useState(false);
  const t = (key) => getTranslation(selectedLanguage, key);

  useEffect(() => {
    if (!isOpen) return;
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const handleDownload = async (e) => {
    e?.stopPropagation();
    setIsDownloading(true);
    try {
      await onDownload();
      onClose();
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {/* Overlay */}
      <div 
        className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm transition-opacity"
        style={{ 
          position: 'fixed',
          zIndex: 11000,
          minWidth: '100vw',
          minHeight: '100vh',
          margin: 0,
          padding: 0
        }}
        onClick={onClose}
      />

      {/* Modal Container */}
      <div 
        className="fixed inset-0 flex items-center justify-center"
        style={{ 
          position: 'fixed',
          zIndex: 11001,
          minWidth: '100vw',
          minHeight: '100vh',
          margin: 0,
          padding: 4
        }}
      >
        <div className="relative bg-white rounded-2xl shadow-xl w-full max-w-2xl mx-4">
          <div className="p-6">
            {/* Close button */}
            <button 
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 transition-colors duration-200"
              aria-label={t('modal.close')}
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* Modal content */}
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900 mb-2">
                {t('modal.title')}
              </h2>
              
              {/* Offer badge */}
              <div className="inline-block bg-rose-100 text-rose-600 text-xs font-semibold px-3 py-1 rounded-full mb-4">
                {t('modal.specialOffer')}
              </div>

              {/* Description */}
              <p className="text-gray-600 text-sm mb-5">
                {t('modal.description')} <br/>
                {t('modal.sharingInfo')}
              </p>

              {/* Features */}
              <div className="grid md:grid-cols-2 gap-4 mb-5">
                <div className="flex items-start p-4 bg-rose-50 rounded-xl">
                  <div className="w-8 h-8 bg-rose-100 rounded-lg flex items-center justify-center mr-3 shrink-0">
                    <svg className="w-4 h-4 text-rose-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                    </svg>
                  </div>
                  <div className="text-left">
                    <h3 className="font-medium text-gray-900 text-sm mb-1">
                      {t('modal.features.premium.title')}
                    </h3>
                    <p className="text-gray-600 text-xs">
                      {t('modal.features.premium.description')}
                    </p>
                  </div>
                </div>

                <div className="flex items-start p-4 bg-rose-50 rounded-xl">
                  <div className="w-8 h-8 bg-rose-100 rounded-lg flex items-center justify-center mr-3 shrink-0">
                    <svg className="w-4 h-4 text-rose-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"/>
                    </svg>
                  </div>
                  <div className="text-left">
                    <h3 className="font-medium text-gray-900 text-sm mb-1">
                      {t('modal.features.secure.title')}
                    </h3>
                    <p className="text-gray-600 text-xs">
                      {t('modal.features.secure.description')}
                    </p>
                  </div>
                </div>
              </div>

              {/* Pricing section */}
              <div className="bg-gray-50 rounded-xl p-4 mb-4">
                {/* Timer */}
                <div className="text-sm text-gray-600 mb-2">
                  {t('modal.timer.prefix')} 
                  <span className="text-rose-600 font-semibold ml-2">{formatTime(timeLeft)}</span>
                </div>

                {/* Price */}
                <div className="flex items-center justify-center gap-2 mb-3">
                  <span className="text-gray-400 text-base line-through">
                    {t('modal.timer.price.original')}
                  </span>
                  <span className="text-2xl font-bold text-gray-900">
                    {t('modal.timer.price.discounted')}
                  </span>
                  <span className="text-gray-500 text-sm">
                    {t('modal.timer.price.suffix')}
                  </span>
                </div>

                {/* CTA Button */}
                <button
                  onClick={handleDownload}
                  disabled={isDownloading}
                  className={`w-full ${
                    isDownloading ? 'bg-rose-400' : 'bg-rose-600 hover:bg-rose-700'
                  } text-white font-medium py-2.5 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center gap-2`}
                >
                  <span>
                    {isDownloading ? t('modal.downloadButton.loading') : t('modal.downloadButton.default')}
                  </span>
                  {isDownloading ? (
                    <svg className="w-4 h-4 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
                    </svg>
                  ) : (
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                    </svg>
                  )}
                </button>
              </div>

              {/* Trust indicators */}
              <div className="flex items-center justify-center gap-1.5 text-xs text-gray-500">
                <svg className="w-4 h-4 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" 
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" 
                    clipRule="evenodd"/>
                </svg>
                <span>{t('modal.trustBadge')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BiodataDownloadModal;