// ImageUploader.jsx
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FaCloudUploadAlt, FaTrash, FaRedo, FaMinus, FaPlus } from 'react-icons/fa';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getTranslation } from './translations';

const ImageUploader = ({ value, onChange, maxSize = 20, selectedLanguage }) => {
  const fileInputRef = useRef(null);
  const imgRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [tempImage, setTempImage] = useState(null);
  
  const t = (key) => getTranslation(selectedLanguage, key);

  // Reset all states
  const resetStates = useCallback(() => {
    setDragActive(false);
    setShowCropModal(false);
    setIsLoading(false);
    setCrop(null);
    setCompletedCrop(null);
    setZoom(1);
    setTempImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset file input
    }
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      resetStates();
    };
  }, [resetStates]);

  const onImageLoad = useCallback((img) => {
    if (!img) return;
  
    // Use the full image dimensions instead of forcing a square
    const width = img.width;
    const height = img.height;
    const x = 0;
    const y = 0;
  
    setCrop({
      unit: 'px',
      width,
      height,
      x,
      y
    });
  }, []);

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === "dragenter" || e.type === "dragover");
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    handleFile(file);
  }, []);

  const handleFile = useCallback(async (file) => {
    if (!file) return;

    try {
      setIsLoading(true);

      if (file.size > maxSize * 1024 * 1024) {
        alert(`${t('imageUploader.fileSizeError')} ${maxSize}MB`);
        resetStates();
        return;
      }

      const reader = new FileReader();
      
      const imageLoaded = new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => {
          reject(error);
          resetStates();
        };
      });

      reader.readAsDataURL(file);

      const imageData = await imageLoaded;
      setTempImage(imageData);
      setShowCropModal(true);
      setZoom(1);
      setCrop(null);

    } catch (error) {
      console.error('Error loading image:', error);
      alert('Error loading image. Please try again.');
      resetStates();
    } finally {
      setIsLoading(false);
    }
  }, [maxSize, t, resetStates]);

  const getCroppedImg = useCallback(async (sourceImage, cropConfig, scale = 1) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = sourceImage;
      
      image.onload = () => {
        try {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          if (!ctx) {
            reject(new Error('No 2d context'));
            return;
          }
  
          // Get scaling factors based on natural image dimensions
          const scaleX = image.naturalWidth / image.width;
          const scaleY = image.naturalHeight / image.height;
  
          // If no crop area is selected, scale the entire image
          if (!cropConfig || !cropConfig.width || !cropConfig.height) {
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;
  
            // Apply zoom to the entire image when no crop is selected
            ctx.scale(scale, scale);
            ctx.drawImage(
              image,
              0,
              0,
              image.naturalWidth / scale,
              image.naturalHeight / scale
            );
            
            canvas.toBlob(
              (blob) => {
                if (!blob) {
                  reject(new Error('Canvas is empty'));
                  return;
                }
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
              },
              'image/png',
              1
            );
            return;
          }
  
          // Calculate actual crop dimensions considering the zoom level
          const scaledCropWidth = (cropConfig.width * scaleX) / scale;
          const scaledCropHeight = (cropConfig.height * scaleY) / scale;
          const scaledCropX = (cropConfig.x * scaleX) / scale;
          const scaledCropY = (cropConfig.y * scaleY) / scale;
  
          // Set canvas dimensions to match the final cropped size
          canvas.width = scaledCropWidth * scale;
          canvas.height = scaledCropHeight * scale;
  
          // Clear any existing content
          ctx.clearRect(0, 0, canvas.width, canvas.height);
  
          // Scale the context to apply zoom
          ctx.scale(scale, scale);
  
          // Apply the crop
          ctx.drawImage(
            image,
            scaledCropX,
            scaledCropY,
            scaledCropWidth,
            scaledCropHeight,
            0,
            0,
            scaledCropWidth,
            scaledCropHeight
          );
  
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error('Canvas is empty'));
                return;
              }
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
            },
            'image/png',
            1
          );
        } catch (error) {
          reject(error);
        }
      };
  
      image.onerror = reject;
    });
  }, []);


  const handleCancel = useCallback(() => {
    resetStates();
  }, [resetStates]);

  const handleSave = useCallback(async () => {
    if (!imgRef.current || !completedCrop || !tempImage) {
      resetStates();
      return;
    }

    try {
      setIsLoading(true);
      const croppedImageUrl = await getCroppedImg(
        tempImage,
        completedCrop,
        zoom
      );
      onChange(croppedImageUrl);
      resetStates();
    } catch (error) {
      console.error('Error saving cropped image:', error);
      alert('Error saving image. Please try again.');
      resetStates();
    } finally {
      setIsLoading(false);
    }
  }, [completedCrop, tempImage, zoom, onChange, resetStates, getCroppedImg]);

  const handleRemove = useCallback((e) => {
    e.stopPropagation();
    onChange('');
    resetStates();
  }, [onChange, resetStates]);

  const resetImage = useCallback(() => {
    if (imgRef.current) {
      onImageLoad(imgRef.current);
    }
    setZoom(1);
  }, [onImageLoad]);

  return (
    <div className="w-full">
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-rose-500 border-t-transparent shadow-lg"></div>
        </div>
      )}

      {!value ? (
        <div
          className={`border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-300 shadow-sm
            ${dragActive 
              ? 'border-rose-500 bg-rose-50 scale-102 shadow-md' 
              : 'border-gray-300 hover:border-rose-400 hover:bg-gray-50'}`}
          onClick={() => fileInputRef.current?.click()}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <FaCloudUploadAlt className="w-14 h-14 mx-auto text-gray-400 mb-4 transition-colors duration-300 group-hover:text-rose-500" />
          <p className="text-gray-600 mb-2 font-medium">{t('imageUploader.dragDrop')}</p>
          <button className="text-rose-500 font-semibold hover:text-rose-600 transition-colors duration-200">
            {t('imageUploader.browse')}
          </button>
          <p className="text-gray-400 text-sm mt-2">{t('imageUploader.maxSize')} {maxSize}MB</p>
        </div>
      ) : (
        <div className="relative group rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300">
          <img 
            src={value} 
            alt={t('imageUploader.preview')}
            className="w-full object-contain rounded-xl"
            style={{ maxHeight: '250px' }}
          />
          <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-300 rounded-xl flex items-center justify-center">
            <button
              onClick={handleRemove}
              className="opacity-0 group-hover:opacity-100 bg-white p-3 rounded-full text-red-500 hover:bg-red-50 hover:text-red-600 transition-all duration-300 transform hover:scale-110 shadow-lg"
              title={t('imageUploader.remove')}
            >
              <FaTrash className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}
      
      {showCropModal && (
  <div className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex items-center justify-center z-50">
    <div className="bg-white rounded-xl w-[400px] flex flex-col shadow-2xl transform transition-all duration-300">
      <div className="p-4 border-b border-gray-100">
        <h3 className="text-lg font-semibold text-center text-gray-800">Adjust Image</h3>
      </div>

      <div className="flex-1 p-0 flex items-center justify-center bg-gray-50 overflow-hidden">
        <div className="relative flex items-center justify-center w-full h-full">
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            className="flex items-center justify-center"
            style={{ maxHeight: '100%', margin: 'auto' }}
          >
            <img
              ref={imgRef}
              src={tempImage}
              alt="Crop preview"
              onLoad={(e) => onImageLoad(e.currentTarget)}
              style={{ 
                transform: `scale(${zoom})`,
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'contain',
                margin: 'auto'
              }}
            />
          </ReactCrop>
        </div>
      </div>

      <div className="p-4 border-t border-gray-100 space-y-4 bg-gray-50 rounded-b-xl"> 
        <div className="w-full mx-auto flex items-center gap-3 px-2">
          <div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">
            <FaMinus className="w-2 h-2 text-gray-600" />
          </div>
          
          <div className="flex-1">
            <input
              type="range"
              min="0.1"
              max="3"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(parseFloat(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-rose-500"
            />
          </div>

          <div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">
            <FaPlus className="w-2 h-2 text-gray-600" />
          </div>
        </div>

        <div className="flex justify-center gap-2">
          <button
            onClick={handleCancel}
            className="px-4 py-1 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>

          <button
            onClick={handleSave}
            className="px-4 py-1 text-sm font-medium bg-rose-500 text-white rounded-lg hover:bg-rose-600 transition-all duration-200 shadow-sm hover:shadow"
          >
            Save
          </button>
          <button
            onClick={resetImage}
            className="px-4 py-1 text-sm font-medium text-gray-600 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-200"
          >
            Reset
          </button>
          
        </div>
      </div>
    </div>
  </div>
)}


      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            handleFile(file);
          }
        }}
        className="hidden"
      />
    </div>
  );
};

export default ImageUploader;