import React from 'react';

const PrivacyPolicy = () => {
 return (
   <div className="bg-rose-50 min-h-screen">
     <div className="max-w-4xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
       <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
         Privacy Policy
       </h1>

       <div className="bg-white rounded-lg shadow-sm p-8 space-y-6">
         <section className="space-y-4">
           <h2 className="text-xl font-semibold text-gray-800">
             Your Privacy Matters
           </h2>
           <p className="text-gray-600 leading-relaxed">
             At Matrimonial Biodata, we take your privacy seriously. This policy explains how we handle your information when you use our biodata creation service.
           </p>

           <h2 className="text-xl font-semibold text-gray-800 mt-6">
             Information We Don't Collect
           </h2>
           <p className="text-gray-600 leading-relaxed">
             Our service is designed with privacy in mind. When you create a biodata:
           </p>
           <ul className="list-disc pl-6 text-gray-600 space-y-2">
             <li>We don't store your personal information on our servers</li>
             <li>All biodata creation happens directly in your browser</li>
             <li>Your photos and information remain on your device</li>
             <li>We don't maintain any database of user biodatas</li>
           </ul>

           <h2 className="text-xl font-semibold text-gray-800 mt-6">
             Information We Do Collect
           </h2>
           <p className="text-gray-600 leading-relaxed">
             We collect minimal data to improve our service:
           </p>
           <ul className="list-disc pl-6 text-gray-600 space-y-2">
             <li>Basic usage statistics (number of biodatas created, templates used)</li>
             <li>Anonymous website analytics (pages visited, time spent)</li>
             <li>Technical information (browser type, device type)</li>
           </ul>

           <h2 className="text-xl font-semibold text-gray-800 mt-6">
             How We Use Analytics
           </h2>
           <p className="text-gray-600 leading-relaxed">
             We use Google Analytics to understand how visitors use our website. This helps us:
           </p>
           <ul className="list-disc pl-6 text-gray-600 space-y-2">
             <li>Improve our templates and features</li>
             <li>Fix technical issues</li>
             <li>Make our service more user-friendly</li>
             <li>Understand which features are most valuable to users</li>
           </ul>

           <h2 className="text-xl font-semibold text-gray-800 mt-6">
             Your Data Control
           </h2>
           <p className="text-gray-600 leading-relaxed">
             Since we don't store your personal information or biodatas:
           </p>
           <ul className="list-disc pl-6 text-gray-600 space-y-2">
             <li>You maintain complete control over your information</li>
             <li>Your biodata exists only on your device</li>
             <li>You can download and delete your biodata as needed</li>
             <li>There's no need for account creation or data retention</li>
           </ul>

           <h2 className="text-xl font-semibold text-gray-800 mt-6">
             Cookies and Local Storage
           </h2>
           <p className="text-gray-600 leading-relaxed">
             We use minimal cookies and local storage to:
           </p>
           <ul className="list-disc pl-6 text-gray-600 space-y-2">
             <li>Remember your template preferences</li>
             <li>Maintain your current biodata draft while you work</li>
             <li>Improve website performance</li>
           </ul>

           <h2 className="text-xl font-semibold text-gray-800 mt-6">
             Third-Party Services
           </h2>
           <p className="text-gray-600 leading-relaxed">
             Our website uses limited third-party services:
           </p>
           <ul className="list-disc pl-6 text-gray-600 space-y-2">
             <li>Google Analytics for anonymous usage statistics</li>
             <li>Cloud storage for hosting template files</li>
             <li>Content delivery networks for better performance</li>
           </ul>

           <h2 className="text-xl font-semibold text-gray-800 mt-6">
             Children's Privacy
           </h2>
           <p className="text-gray-600 leading-relaxed">
             Our service is intended for users aged 18 and above. We do not knowingly collect or process information from anyone under 18 years of age.
           </p>

           <h2 className="text-xl font-semibold text-gray-800 mt-6">
             Updates to Privacy Policy
           </h2>
           <p className="text-gray-600 leading-relaxed">
             We may update this privacy policy as our service evolves. Any significant changes will be announced on our website. Your continued use of Matrimonial Biodata indicates acceptance of these privacy practices.
           </p>

           <h2 className="text-xl font-semibold text-gray-800 mt-6">
             Contact Us
           </h2>
           <p className="text-gray-600 leading-relaxed">
             If you have questions about our privacy practices or need assistance, please contact us at support@matrimonialbiodata.in.
           </p>

           <p className="text-sm text-gray-500 mt-8 text-center">
             Last updated: March 20, 2023
           </p>
         </section>
       </div>
     </div>
   </div>
 );
};

export default PrivacyPolicy;