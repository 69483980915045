// src/components/Hero.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaUserEdit, FaDownload, FaShare } from 'react-icons/fa';

const Hero = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="bg-gradient-to-b from-rose-50 to-white pb-12 pt-2 md:pt-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <div className="flex flex-col md:grid md:grid-cols-2 gap-8 md:gap-12 items-center">
          {/* Image Section - Moved to top for mobile */}
          <div className="relative flex items-center justify-center order-1 md:order-2 w-full max-w-[400px] mx-auto">
            <div className="relative w-full bg-rose-50/80 rounded-2xl p-4 shadow-lg">
              {/* Single decorative border */}
              <div className="absolute inset-4 border-2 border-rose-200/70 rounded-3xl"></div>
              
              {/* Image container */}
              <div className="relative flex justify-center items-center p-3">
                {/* Loading placeholder */}
                {!imageLoaded && (
                  <div className="absolute inset-0 flex items-center justify-center bg-rose-50">
                    <div className="w-8 h-8 border-4 border-rose-200 border-t-rose-500 rounded-full animate-spin"></div>
                  </div>
                )}
                <img 
                  src="/images/hero-couple.webp"
                  alt="Hero couple preview"
                  className={`w-full max-w-[350px] h-auto object-cover transform hover:scale-105 transition-transform duration-500 ${
                    imageLoaded ? 'opacity-100' : 'opacity-0'
                  }`}
                  onLoad={() => setImageLoaded(true)}
                />
              </div>
              
              {/* Background decorative elements */}
              <div className="absolute -bottom-3 -right-3 w-20 h-20 bg-rose-500/20 rounded-full blur-sm"></div>
              <div className="absolute -top-3 -left-3 w-16 h-16 bg-rose-300/20 rounded-full blur-sm"></div>
            </div>
          </div>

          {/* Rest of the code remains the same */}
          <div className="space-y-6 order-2 md:order-1 text-center md:text-left mt-6 md:mt-0 md:pl-12">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900">
              Create Your Perfect
              <span className="text-rose-500 block mt-2">
                Biodata in Minutes
              </span>
            </h1>
            
            <p className="text-lg text-gray-600 leading-relaxed">
              Create your perfect matrimonial biodata with our elegant templates in minutes. Simple, professional, and uniquely you.
            </p>

            <div className="flex flex-wrap gap-4 justify-center md:justify-start">
              <Link
                to="/create-biodata"
                className="inline-flex items-center px-6 py-3 bg-rose-500 text-white font-medium rounded-full hover:bg-rose-600 transition-colors duration-200"
              >
                Create My Biodata
                <FaArrowRight className="ml-2 w-4 h-4" />
              </Link>
              
              <Link
                to="/templates"
                className="inline-flex items-center px-6 py-3 border-2 border-rose-500 text-rose-500 font-medium rounded-full hover:bg-rose-50 transition-colors duration-200"
              >
                View Templates
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;