// Template7.jsx
import React from 'react';
import {
  getFieldLabel,
  formatHeight,
  formatDate,
  formatTime,
  formatPhone,
  formatSiblings,
  formatValue,
  hasValue,
  shouldShowField,
  processFields
} from '../hooks/templateUtils';
import { getTranslation, monthTranslations } from '../translations';

const Template7 = ({ formData, selectedLanguage, sections, customLabels, isTemplateView = false }) => {
  const t = (key) => getTranslation(selectedLanguage, key);

  const getSectionTitle = (section) => {
    // First check if section has a custom title
    if (section.title && typeof section.title === 'string' && section.title !== t(`sections.${section.id}.title`)) {
      return section.title;
    }
    // Fallback to translation
    return t(`sections.${section.id}.title`);
  };


  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-[500px] text-gray-300">
      <svg 
        className="w-20 h-20 text-gray-400" 
        fill="none" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        viewBox="0 0 24 24" 
      >
        <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
      </svg>
      <h3 className="mb-2 text-xl font-medium text-gray-300">No Preview Available</h3>
      <p className="text-center text-gray-400 max-w-sm">
        Start filling in your information to see<br />your biodata preview in real-time.
      </p>
    </div>
  );

  const isFormEmpty = () => {
    return Object.keys(formData).length === 0 || 
           Object.values(formData).every(section => 
             Object.keys(section).length === 0 || 
             Object.values(section).every(value => !hasValue(value))
           );
  };

  const TitleDecoration = ({ children }) => (
    <div className="relative py-2">
      <div className="absolute left-0 right-0 top-0 h-[2px]" 
           style={{ background: 'linear-gradient(to right, transparent, #8B6B29, transparent)' }} />
      <div className="absolute left-0 right-0 bottom-0 h-[2px]" 
           style={{ background: 'linear-gradient(to right, transparent, #8B6B29, transparent)' }} />
      <div className="flex items-center justify-center gap-3">
      <span style={{ color: '#8B6B29', marginBottom: '16px' }}>❧</span>

        {children}
        <span style={{ color: '#8B6B29', marginBottom: '16px' }}>❧</span>
      </div>
    </div>
  );

  return (
    <div className="relative rounded-lg shadow-lg p-2 flex flex-col min-h-[800px] w-full" 
    style={{
       backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('/images/templates/ganesha-background.png')`,
       backgroundPosition: 'center',
       backgroundSize: '60%',
       backgroundRepeat: 'no-repeat'
    }}>
      {/* Decorative Border */}
      <div className="absolute inset-0">
        <div className="h-full w-full border-4 rounded-lg" style={{ borderColor: '#ba933f' }}>
          {/* Inner border with abstract design */}
          <div className="absolute inset-2 rounded-lg opacity-10" style={{ backgroundColor: '#ba933f' }} />
          
          {/* Abstract Corner Designs */}
          <div className="absolute -top-2 -left-2 w-16 h-16">
            <div className="absolute w-12 h-12 border-t-4 border-l-4 rounded-tl-xl" style={{ borderColor: '#ba933f' }} />
            <div className="absolute w-8 h-8 rotate-45 transform origin-bottom-right" style={{ backgroundColor: '#ba933f', opacity: 0.2 }} />
          </div>

          <div className="absolute -top-2 -right-2 w-16 h-16">
            <div className="absolute w-12 h-12 border-t-4 border-r-4 rounded-tr-xl right-0" style={{ borderColor: '#ba933f' }} />
            <div className="absolute w-8 h-8 -rotate-45 transform origin-bottom-left right-0" style={{ backgroundColor: '#ba933f', opacity: 0.2 }} />
          </div>

          <div className="absolute -bottom-2 -left-2 w-16 h-16">
            <div className="absolute w-12 h-12 border-b-4 border-l-4 rounded-bl-xl bottom-0" style={{ borderColor: '#ba933f' }} />
            <div className="absolute w-8 h-8 -rotate-45 transform origin-top-right bottom-0" style={{ backgroundColor: '#ba933f', opacity: 0.2 }} />
          </div>

          <div className="absolute -bottom-2 -right-2 w-16 h-16">
            <div className="absolute w-12 h-12 border-b-4 border-r-4 rounded-br-xl bottom-0 right-0" style={{ borderColor: '#ba933f' }} />
            <div className="absolute w-8 h-8 rotate-45 transform origin-top-left bottom-0 right-0" style={{ backgroundColor: '#ba933f', opacity: 0.2 }} />
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="relative w-[85%] max-w-3xl mx-auto styled-scrollbar px-4 flex flex-col flex-grow">
        {isFormEmpty() ? (
          <EmptyState />
        ) : (
          <>
            {/* Mantra with God Images */}
            <div className="flex items-center justify-center gap-2 mt-0">
            {/* Left God Image */}
<div className="flex items-center justify-center w-10">
  <img 
    src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
    alt="God Image Left"
    className="w-10 h-10 object-contain" 
    onError={(e) => {
      e.target.src = '/images/gods/ganesha1.png';
      e.target.onerror = null;
    }}
  />
</div>
              
              <div className="flex items-center justify-center">
                <div className="font-medium text-xl mb-4" style={{ fontFamily: 'Birthstone', lineHeight: '40px', color: '#4A3618' }}>
                  {formData?.customization?.mantra || t('sections.customization.fields.mantra.placeholder')}
                </div>
              </div>
              
             {/* Right God Image */}
<div className="flex items-center justify-center w-10">
  <img 
    src={`/images/gods/${formData?.customization?.godImage || 'ganesha1.png'}`}
    alt="God Image Right"
    className="w-10 h-10 object-contain" 
    onError={(e) => {
      e.target.src = '/images/gods/ganesha1.png';
      e.target.onerror = null;
    }}
  />
</div>
            </div>

            {/* Biodata Title */}
            <TitleDecoration>
              <h1 className="text-xl font-bold mb-4 text-center" style={{ color: '#4A3618' }}>
                {formData?.customization?.biodataTitle || t('sections.customization.fields.biodataTitle.placeholder')}
              </h1>
            </TitleDecoration>
              
            {/* Profile Name */}
            {formData?.personalDetails?.name && (
              <h2 className="text-2xl font-semibold mb-4 text-center" style={{ color: '#4A3618' }}>
                {formData.personalDetails.name}
              </h2>
            )}

            {/* Content Sections */}
            <div className="space-y-4">
              {sections.map((section) => {
                if (section.id === 'customization') return null;

                const fields = processFields(section, formData, shouldShowField);
                if (fields.length === 0) return null;

                return (
                  <div key={section.id} className="mb-0">
                    {section.id === 'personalDetails' ? (
                      <>
                        <div className="flex items-center mb-0">
                          <div className="flex-grow h-[1px]" style={{ backgroundColor: '#ba933f', opacity: 0.3 }} />
                          <h3 className="px-3 mb-4 text-base font-medium" style={{ color: '#4A3618' }}>
                            {getSectionTitle(section)}
                          </h3>
                          <div className="flex-grow h-[1px]" style={{ backgroundColor: '#ba933f', opacity: 0.3 }} />
                        </div>
                        <div className="flex justify-between items-start gap-1">
                          <div className="flex-1 min-w-0">
                            <div className="space-y-1 text-left text-sm">
                            {fields.map(field => {
  const sectionData = formData[section.id] || {};
  const value = sectionData[field.id];
  let formattedValue = formatValue(value, field.type, field.id, section.id, {
    selectedLanguage,
    monthTranslations,
    t,
    getFieldLabel
  });

  if (field.id === 'education' && sectionData.collegeName) {
    formattedValue = `${formattedValue} (${sectionData.collegeName})`;
  } else if (field.id === 'occupation' && sectionData.organizationName) {  // Add this condition
    formattedValue = `${formattedValue} (${sectionData.organizationName})`;
  }

  if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
    return null;
  }

  return (
    <div key={field.id} className="flex py-0">
      <div className={`${formData?.photoUpload?.profilePhoto ? 'w-[200px]' : 'w-[180px]'}`} style={{ color: '#806231' }}>
        {getFieldLabel(section.id, field.id, customLabels, sections, t)}
      </div>
      <div className="w-[70%]" style={{ color: '#5C4419' }}>
        <div className="flex">
          <span className="mr-2">:</span>
          <div className="flex-1 break-words">{formattedValue}</div>
        </div>
      </div>
    </div>
  );
})}
                            </div>
                          </div>
                          {formData?.photoUpload?.profilePhoto && (
                            <div className="w-36 h-auto flex-shrink-0 rounded-lg overflow-hidden" 
                                 style={{ border: '1px solid #ba933f' }}>
                              <img 
                                src={formData.photoUpload.profilePhoto}
                                alt="Profile"
                                className="w-full h-full object-cover"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.style.display = 'none';
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center">
                          <div className="flex-grow h-[1px]" style={{ backgroundColor: '#ba933f', opacity: 0.3 }} />
                          <h3 className="px-3 text-base font-medium mb-4" style={{ color: '#4A3618' }}>
                            {getSectionTitle(section)}
                          </h3>
                          <div className="flex-grow h-[1px]" style={{ backgroundColor: '#ba933f', opacity: 0.3 }} />
                        </div>
                        <div className="space-y-1 text-left text-sm">
                          {fields.map(field => {
                            const sectionData = formData[section.id] || {};
                            if (field.isSiblingInfo && section.id === 'familyDetails') {
                              return (
                                <div key="siblings_info" className="flex py-0">
                                  <div className="w-[180px]" style={{ color: '#806231' }}>Siblings</div>
                                  <div className="w-[70%]" style={{ color: '#5C4419' }}>
                                    <div className="flex">
                                      <span className="mr-2">:</span>
                                      <div className="flex-1 break-words">{formatSiblings(sectionData)}</div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

                            const value = sectionData[field.id];
  let formattedValue = formatValue(value, field.type, field.id, section.id, {
    selectedLanguage,
    monthTranslations,
    t,
    getFieldLabel
  });

  if (field.id === 'education' && sectionData.collegeName) {
    formattedValue = `${formattedValue} (${sectionData.collegeName})`;
  } else if (field.id === 'occupation' && sectionData.organizationName) {
    formattedValue = `${formattedValue} (${sectionData.organizationName})`;
  } else if (field.id === 'fatherOccupation' && sectionData.fatherOrganization) {
    formattedValue = `${formattedValue} (${sectionData.fatherOrganization})`;
  } else if (field.id === 'motherOccupation' && sectionData.motherOrganization) {
    formattedValue = `${formattedValue} (${sectionData.motherOrganization})`;
  }

  if (!formattedValue || ['collegeName', 'organizationName', 'fatherOrganization', 'motherOrganization'].includes(field.id)) {
    return null;
  }

  return (
    <div key={field.id} className="flex py-0">
      <div className="w-[180px]" style={{ color: '#806231' }}>
        {getFieldLabel(section.id, field.id, customLabels, sections, t)}
      </div>
      <div className="w-[70%]" style={{ color: '#5C4419' }}>
        <div className="flex">
          <span className="mr-2">:</span>
          <div className="flex-1 break-words">{formattedValue}</div>
        </div>
      </div>
    </div>
  );
})}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            {/* Footer */}
            <div className="mt-auto mb-1 text-center">
              <div className="flex items-center justify-center w-full my-1 opacity-80">
                <div className="flex-grow h-[1px] max-w-[100px]" 
                     style={{ background: 'linear-gradient(to right, transparent, #ba933f, transparent)',  marginTop: '16px' }} />
                <div className="mx-4" style={{ color: '#ba933f' }}>
                  <span className="text-lg">✤</span>
                </div>
                <div className="flex-grow h-[1px] max-w-[100px]" 
                     style={{ background: 'linear-gradient(to right, transparent, #ba933f, transparent)', marginTop: '16px' }} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Template7.isPremium = true;
export default Template7;