// EditableLabel.jsx
import React, { useState, useRef, useEffect } from 'react';
import { FaPencilAlt } from 'react-icons/fa';

const EditableLabel = ({ initialValue, onSave, className = '', required = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setValue(initialValue);
    }
  };

  const handleSave = () => {
    const trimmedValue = value.trim();
    if (trimmedValue && trimmedValue !== initialValue.trim()) {
      onSave(trimmedValue);
    }
    setIsEditing(false);
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  return (
    <div className="group relative inline-flex items-center editable-label">
      {isEditing ? (
        <div className="flex items-center w-full">
          <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onClick={(e) => e.stopPropagation()}
            className={`w-full px-1 py-0.5 border border-rose-500 rounded-md focus:outline-none focus:ring-1 focus:ring-rose-500 bg-white ${className}`}
          />
        </div>
      ) : (
        <div 
          className="inline-flex items-center gap-1 cursor-text w-full"
          onClick={handleClick}
        >
          <span className={`${className} flex-1`}>
            {value}
            {required && <span className="text-rose-500 ml-1">*</span>}
          </span>
          <FaPencilAlt 
            className="w-3.5 h-3.5 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:text-rose-500" 
          />
        </div>
      )}
    </div>
  );
};

export default EditableLabel;