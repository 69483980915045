import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const footerLinks = [
    { label: 'About Us', path: '/about-us' },
    { label: 'Terms & Conditions', path: '/terms-conditions' },
    { label: 'Privacy Policy', path: '/privacy-policy' },
    { label: 'Refund Policy', path: '/refund-policy' },
    { label: 'FAQs', path: '/faqs' },
    { label: 'Contact Us', path: '/contact-us' }
  ];

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="bg-white border-t border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Main Footer Content */}
        <div className="py-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Logo and Description */}
            <div className="space-y-2">
              <Link to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className="inline-block">
                <img 
                  src="/images/matrimonialbiodatalogo.webp" 
                  alt="Matrimonial Biodata" 
                  className="h-12 w-auto"
                />
              </Link>
              <p className="text-gray-600 text-base max-w-md">
                Create Professional Marriage Biodata<br></br> with our easy-to-use platform for Free.
              </p>
            </div>

            {/* Navigation Links */}
            <div className="grid grid-cols-2 gap-8 sm:grid-cols-3">
              {/* Quick Links */}
              <div className="space-y-4">
                <h3 className="text-sm font-bold text-gray-800 uppercase tracking-wider">
                  Quick Links
                </h3>
                <div className="flex flex-col space-y-3">
                  {footerLinks.slice(0, 2).map((link, index) => (
                    <button
                      key={index}
                      onClick={() => handleNavigation(link.path)}
                      className="text-left text-base text-gray-600 hover:text-rose-500 transition-colors duration-200"
                    >
                      {link.label}
                    </button>
                  ))}
                </div>
              </div>
              
              {/* Legal */}
              <div className="space-y-4">
                <h3 className="text-sm font-bold text-gray-800 uppercase tracking-wider">
                  Legal
                </h3>
                <div className="flex flex-col space-y-3">
                  {footerLinks.slice(2, 4).map((link, index) => (
                    <button
                      key={index}
                      onClick={() => handleNavigation(link.path)}
                      className="text-left text-base text-gray-600 hover:text-rose-500 transition-colors duration-200"
                    >
                      {link.label}
                    </button>
                  ))}
                </div>
              </div>

              {/* Help & Support */}
              <div className="space-y-4">
                <h3 className="text-sm font-bold text-gray-800 uppercase tracking-wider">
                  Help & Support
                </h3>
                <div className="flex flex-col space-y-3">
                  {footerLinks.slice(4).map((link, index) => (
                    <button
                      key={index}
                      onClick={() => handleNavigation(link.path)}
                      className="text-left text-base text-gray-600 hover:text-rose-500 transition-colors duration-200"
                    >
                      {link.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="border-t border-gray-100 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-500 text-sm text-center md:text-left">
              © {currentYear} Matrimonial Biodata. All Rights Reserved.
            </p>
            <p className="text-gray-500 text-sm text-center md:text-right">
              Made with ❤️ for Happy Marriages!
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;