import React, { useState, useEffect } from 'react';

const Spinner = () => (
  <div className="absolute inset-0 flex items-center justify-center bg-white">
    <div className="w-6 h-6 border-2 border-gray-200 border-t-gray-600 rounded-full animate-spin"></div>
  </div>
);



const Modal = ({ isOpen, onClose, imageSrc }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      setIsLoading(true);
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="relative w-3/4 bg-white rounded-lg overflow-hidden max-h-[90vh]"
        onClick={(e) => e.stopPropagation()}
      >
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 z-10 w-8 h-8 flex items-center justify-center rounded-full 
            bg-white shadow-lg hover:bg-gray-100 transition-colors duration-200 group"
        >
          <svg 
            className="w-4 h-4 text-gray-600 group-hover:text-gray-800" 
            fill="none" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        
        <div 
          className="overflow-y-auto scrollbar-custom p-2"
          style={{
            scrollbarWidth: 'thin',
            scrollbarColor: '#CBD5E1 transparent',
            maxHeight: '90vh'
          }}
        >
          {isLoading && <Spinner />}
          <img 
            src={imageSrc} 
            alt="Template Preview" 
            onLoad={handleImageLoad}
            className={`
              w-full h-auto object-contain
              ${isLoading ? 'opacity-0' : 'opacity-100'}
            `}
            onError={(e) => {
              const newSrc = imageSrc.replace('.webp', '.png');
              if (e.target.src !== newSrc) {
                e.target.src = newSrc;
                setIsLoading(true);
              } else {
                setIsLoading(false);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};



const Templates = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const totalTemplates = 8;

  const handleCardClick = (index) => {
    setSelectedTemplate(index);
    setModalImage(`/images/biodata/biodata${index + 1}.webp`);
  };

  return (
    <div className="bg-gradient-to-b from-rose-100/50 to-white min-h-screen">
      <div className="p-4 sm:p-6 lg:p-8">
        <div className="max-w-6xl mx-auto">
          {/* Enhanced title and description section */}

          <div className="mb-12 text-center pt-4 px-4">
            <span className="text-rose-500 font-medium text-lg mb-4 block">
              Clean & Modern
            </span>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4 tracking-tight">
            Professionally Designed Biodata Templates
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto text-lg md:text-xl leading-relaxed">
            Choose from our collection of professionally designed biodata templates. Each template is carefully crafted to present your personal information in an elegant and organized manner.
            </p>
          </div>


          {/* Enhanced grid layout */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-8 p-2">
            {Array.from({ length: totalTemplates }, (_, index) => (
              <div 
                key={index}
                onClick={() => handleCardClick(index)}
                className={`
                  group relative rounded-xl overflow-hidden cursor-pointer
                  transition-all duration-300 ease-in-out
                  bg-white
                  hover:shadow-[0_8px_30px_rgb(0,0,0,0.12)]
                  hover:scale-[1.02]
                  ${selectedTemplate === index 
                    ? 'ring-2 ring-rose-500 shadow-[0_8px_30px_rgb(0,0,0,0.12)]' 
                    : 'shadow-md'
                  }
                `}
              >
                <div className="p-2">
                  <div className="aspect-[1/1.4142] w-full bg-gray-50 rounded-lg overflow-hidden">
                    <img 
                      src={`/images/biodata/biodata${index + 1}.webp`}
                      alt={`Template ${index + 1}`}
                      className="w-full h-full object-cover transition-all duration-300 
                        group-hover:opacity-95 group-hover:scale-105"
                      onError={(e) => {
                        e.target.src = `/images/biodata/biodata${index + 1}.png`;
                        e.target.onerror = null;
                      }}
                    />
                  </div>
                  
                </div>

                {/* Hover overlay */}
                <div className="absolute inset-0 bg-rose-500/0 group-hover:bg-rose-500/5 transition-colors duration-300" />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal 
        isOpen={modalImage !== null}
        onClose={() => setModalImage(null)}
        imageSrc={modalImage}
      />
    </div>
  );
};

const styles = `
  .scrollbar-custom::-webkit-scrollbar {
    width: 8px;
  }

  .scrollbar-custom::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: rgb(244, 63, 94, 0.3); /* rose-500 with opacity */
    border-radius: 4px;
    border: 2px solid transparent;
  }

  .scrollbar-custom::-webkit-scrollbar-thumb:hover {
    background-color: rgb(244, 63, 94, 0.5); /* rose-500 with higher opacity on hover */
  }

  .scrollbar-custom {
    scrollbar-width: thin;
    scrollbar-color: rgb(244, 63, 94, 0.3) transparent;
  }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Templates;